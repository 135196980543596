import anime from 'animejs';
// libs
import { useEffect } from 'react'; // Hook to handle wave animation

interface Props {
    pathRef?: React.RefObject<SVGPathElement | null>;
}
export const useWaveAnimation = ({ pathRef }: Props) => {
    useEffect(() => {
        const createWaveAnimation = (duration: number, easing: string, chaos = false) => {
            if (pathRef?.current) {
                anime({
                    targets: pathRef.current,
                    d: [
                        {
                            value: 'M0 45.5386C0 20.4723 20.4723 0 45.5413 0C70.5274 0 91.0008 20.4723 91.0008 45.5386V82.4567C91.0008 88.1796 87.0496 90.354 83.0203 85.2779C79.1522 80.4406 76.2503 73.9124 73.9124 73.9124C70.1247 73.9124 67.3034 85 59.7255 85C52.1504 85 49.3292 70 45.5415 70C41.6705 70 38.8506 85 31.2742 85C23.6979 85 20.8765 73.9124 17.0875 73.9124C14.7508 73.9124 11.8489 80.4406 7.97946 85.2779C3.94876 90.3538 0 88.1796 0 82.4567V45.5386Z'
                        },
                        {
                            value: 'M0 45.5386C0 20.4723 20.4723 0 45.5413 0C70.5274 0 91.0008 20.4723 91.0008 45.5386V82.4567C91.0008 92 87.0496 96 83.0203 91C79.1522 86 76.2503 75 73.9124 75C70.1247 75 67.3034 90 59.7255 90C52.1504 90 49.3292 75 45.5415 75C41.6705 75 38.8506 90 31.2742 90C23.6979 90 20.8765 75 17.0875 75C14.7508 75 11.8489 86 7.97946 91C3.94876 96 0 92 0 82.4567V45.5386Z'
                        }
                    ],
                    duration,
                    loop: true,
                    direction: 'alternate',
                    easing,
                    update: function () {
                        if (chaos) {
                            // Add some randomization to the movement
                            anime.set(pathRef.current, {
                                translateX: anime.random(-2, 2),
                                translateY: anime.random(-2, 2),
                            });
                        } else {
                            // Reset any translation when chaos is off
                            anime.set(pathRef.current, {
                                translateX: 0,
                                translateY: 0,
                            });
                        }
                    }
                });
            }
        };

        // Create the initial animation with normal speed
        createWaveAnimation(2000, 'easeInOutQuad');

        // Handle mouse enter event to speed up the animation and add chaos
        const handleMouseEnter = () => {
            createWaveAnimation(300, 'easeInOutQuad', true);
        };

        // Handle mouse leave event to return to normal speed smoothly
        const handleMouseLeave = () => {
            createWaveAnimation(1000, 'easeInOutQuad', false);
        };

        // Add event listeners to the SVG
        const svgElement = pathRef?.current?.closest('svg');
        svgElement?.addEventListener('mouseenter', handleMouseEnter);
        svgElement?.addEventListener('mouseleave', handleMouseLeave);

        // Clean up event listeners on unmount
        return () => {
            svgElement?.removeEventListener('mouseenter', handleMouseEnter);
            svgElement?.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, [pathRef]);
};
