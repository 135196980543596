import { useEffect, useState } from 'react';

import MapApi, { MapContextResponse } from '@/api/apps/map';
import { useTranslate } from '@/hooks/useTranslate';
import { logger } from '@/utils/logger';

export const useLayout = () => {

    // hooks
    const { t } = useTranslate('insider');

    // states
    const [contextData, setContextData] = useState<MapContextResponse | null>(null);
    const [selectedPlaceType, setSelectedPlaceType] = useState<string[]>([]);

    const handleAddPlaceType = (placeType: string) => {
        if (selectedPlaceType.includes(placeType)) {
            setSelectedPlaceType(selectedPlaceType.filter((pt) => pt !== placeType));
        } else {
            setSelectedPlaceType([...selectedPlaceType, placeType]);
        }
    }


    const getContext = async () => {
        await MapApi.getContext().then((res) => {
            if (res.status === 200) {
                setContextData(res.data);
            } else {
                logger.error('Failed to get map context', res);
            }
        });
    }

    useEffect(() => {
        getContext();
    }, []);

    return {
        contextData,
        selectedPlaceType,
        handleAddPlaceType,
    }
}
