
import { useAppContext } from '@/context';
import { Box } from '@mui/material';

import Appbar from './Appbar';
import ContextProvider from './context';
import Footer from './Footer';

import type { LayoutConfig } from './types';

interface Props {
    config: LayoutConfig;
    children: React.ReactNode;
}
const PublicLayout = ({ config, children }: Props) => {

    const { manager: { appProps } } = useAppContext();

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
        }}>
            <Appbar />
            <Box component="main" sx={{
                flexGrow: 1,
            }}>
                {children}
            </Box>

            {appProps.config.layout?.settings?.footerSize !== 'hidden' && (
                <Footer />
            )}

        </Box>
    )
}

export default (props: Props) => (
    <ContextProvider config={props.config}>
        <PublicLayout {...props} />
    </ContextProvider>
);