import { useEffect, useMemo, useState } from 'react';

import ProductHuntApi, { ProductHuntContextResponse } from '@/api/apps/product-hunt';
import { useTranslate } from '@/hooks/useTranslate';
import { logger } from '@/utils/logger';

export const useLayout = () => {

    // hooks
    const { t } = useTranslate('insider');

    // states
    const [contextData, setContextData] = useState<ProductHuntContextResponse | null>(null);

    // computed
    const contextDataTranslated = useMemo(() => {
        if (!contextData) return null;

        return {
            ...contextData,
            categories: contextData.categories.map(category => ({
                ...category,
                name: t(`categories.${category.slug}.name`),
                subcategories: category.subcategories.map(subcategory => ({
                    ...subcategory,
                    label: t(`categories.${category.slug}.${subcategory.slug}`)
                }))
            }))
        };
    }, [contextData, t]);

    const getContext = async () => {
        await ProductHuntApi.getProductContext().then((res) => {
            if (res.status === 200) {
                setContextData(res.data);
            } else {
                logger.error('Failed to get product context', res);
            }
        });
    }

    useEffect(() => {
        getContext();
    }, []);

    return {
        contextData: contextDataTranslated,
    }
}
