import { memo, useCallback, useEffect, useState } from 'react';

import { Box, TextField, Typography } from '@mui/material';

// Types
import { PromptOptions } from './types';
import UniversalDialog from './UniversalDialog';

const initialState: PromptOptions = {
    title: '',
    message: '',
    placeholder: '',
    confirmButton: { label: 'Confirm', color: 'primary' },
    settings: { maxWidth: 'xs' },
};

const PromptDialog = () => {

    // states
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<PromptOptions>(initialState);
    const [onConfirmCallback, setOnConfirmCallback] = useState<((value: string) => void) | null>(null);

    // Computed
    const isDisabled = inputValue.trim() === '';

    // Handle opening the dialog with dynamic options
    const handleOpen = useCallback(
        (options: PromptOptions, onConfirm: (value: string) => void) => {
            const newOptions = {
                ...initialState,
                ...options,
            };
            setOptions(newOptions);
            setInputValue('');
            setOnConfirmCallback(() => onConfirm);
            setOpen(true);
        },
        []
    );

    // Handle closing the dialog
    const handleClose = useCallback(() => {
        setInputValue('');
        setOptions(initialState);
        setOnConfirmCallback(null);
        setOpen(false);
    }, []);

    // Handle confirm button click
    const handleConfirm = useCallback(() => {
        if (onConfirmCallback) {
            onConfirmCallback(inputValue);
        }
        setOpen(false); // Close the dialog first
    }, [inputValue, onConfirmCallback]);

    // Attach the prompt function to window.common
    useEffect(() => {
        if (!window.common) {
            window.common = {} as CommonInterface;
        }

        window.common.prompt = (options: PromptOptions, onConfirm: (value: string) => void) => {
            handleOpen(options, onConfirm);
        };
    }, [handleOpen]);

    // Function to handle Enter key press
    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.key === 'Enter' && !isDisabled) {
                e.preventDefault();
                handleConfirm();
            }
        },
        [isDisabled, handleConfirm]
    );


    const renderMessage = () => {
        if (!options.message) return null;

        if (typeof options.message === 'string') {
            return (
                <Typography variant="body1" sx={{
                    whiteSpace: 'pre-line',
                }}>
                    {options.message.trim()}
                </Typography>
            );
        }

        return options.message;
    }

    return (
        <UniversalDialog
            open={open}
            title={options.title}
            onClose={handleClose}
            maxWidth={options.settings?.maxWidth || 'xs'}
            dialogActions={[
                { children: 'Cancel', onClick: handleClose, color: 'inherit', variant: 'outlined' },
                {
                    children: options.confirmButton?.label,
                    onClick: handleConfirm,
                    color: options.confirmButton?.color,
                    variant: 'contained',
                    disabled: isDisabled,
                },
            ]}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
            }}>
                {renderMessage()}

                <TextField
                    onKeyDown={handleKeyDown}
                    fullWidth
                    autoFocus
                    required
                    autoComplete="off"
                    variant="outlined"
                    placeholder={options.placeholder}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
            </Box>
        </UniversalDialog>
    );
};

export default memo(PromptDialog);
