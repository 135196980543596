class Routes {
	home = '/';

	pages = {
		about: this.route('/about'),
		contact: this.route('/contact'),
	};

	docs = {
		privacy: this.route('/docs/privacy'),
		terms: this.route('/docs/terms'),
	};

	dashboard = {
		index: this.route('/dashboard'),
	};

	billing = {
		index: this.route('/billing'),
	};

	apps = {
		index: this.route('/apps'),
		aiMarketingTool: this.route('/apps/ai-marketing-tool'),
		propertyPortal: this.route('/apps/property-portal'),
		aiPropertyPrice: this.route('/apps/ai-property-price'),
		aiPropertyVideo: this.route('/apps/ai-property-video'),
		trendingNews: this.route('/apps/trending-news'),
		image3d: this.route('/apps/image-3d/[slug]', ['slug']),
		fingerMeasuring: this.route('/apps/finger-measuring'),
		insider: this.route('/~/insider'),
		map: this.route('/~/map'),
		unrealos: this.route('/~/os'),
	};

	auth = {
		login: this.route('/auth'),
		register: this.route('/auth/register'),
		password: {
			reset: this.route('/auth/password/reset'),
			change: this.route('/auth/password/change'),
		},
	};

	admin = {
		index: this.route('/admin'),
	};

	// Sample with parameters
	// projects = {
	// 	index: this.route('/projects'),
	// 	detail: this.route('/projects/[slug]', ['slug']),
	// };

	/**
	 * Helper method to define a route with or without parameters.
	 *
	 * @param path - The base path of the route.
	 * @param params - Optional placeholders for dynamic parts of the route.
	 * @returns Object containing `as` (for dynamic URLs) and `path`.
	 */
	route(path: string, params?: string[]) {
		if (params) {
			const paramPath = params.reduce((acc, param) => acc.replace(`[${param}]`, `:${param}`), path);
			return {
				as: (values: Record<string, string>) =>
					params.reduce((acc, param) => acc.replace(`:${param}`, values[param]), paramPath),
				path: paramPath,
			};
		}
		return { as: () => path, path };
	}
}

const routes = new Routes();

export const routesPreset = {
	userLogged: routes.home,
};

export default routes;
