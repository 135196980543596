import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import env from '@/core/env';

import type { SiteStatusResponse } from '@/pages/api/check-site-status';


class ApiService {
    private axios: AxiosInstance;

    constructor() {

        let baseURL = env.FRONTEND_URL;
        if (typeof window !== 'undefined') {
            // For native CORS
            baseURL = window.location.origin;
        }

        this.axios = axios.create({
            baseURL,
            timeout: 10000, // 10 seconds timeout
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.axios.get<T>(url, config);
    }

    async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.axios.post<T>(url, data, config);
    }
}

const api = new ApiService();

export default class NextApi {
    // static async getProjectsList(): Promise<AxiosResponse<any>> {
    //     const url = '/api/next/projects';
    //     return api.get(url);
    // }

    static async googleAuth(code: string, redirectUri: string): Promise<AxiosResponse<any>> {
        const url = '/api/auth/google';
        return api.post(url, { code, redirectUri });
    }

    static async googleOneTapAuth(credential: string, redirectUri: string): Promise<AxiosResponse<any>> {
        const url = '/api/auth/onetap';
        return api.post(url, { credential, redirectUri });
    }

    static async checkSiteStatus(siteUrl: string): Promise<AxiosResponse<SiteStatusResponse>> {
        const url = '/api/check-site-status';
        return api.post<SiteStatusResponse>(url, { siteUrl });
    }

}
