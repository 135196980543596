import { useRouter } from 'next/router';
import { useState } from 'react';

import config from '@/core/config';
import routes from '@/core/routes';
// MUI icons
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const SupportMenu = () => {

    // hooks
    const router = useRouter();

    // states
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuItems = [
        {
            label: 'Roadmap',
            href: config.links.roadmap,
            icon: <MapOutlinedIcon />,
        },
        {
            label: 'Feedback',
            href: config.links.feedback,
            icon: <FeedbackOutlinedIcon />,
        },
        {
            label: 'Community',
            href: config.links.community,
            icon: <PeopleOutlinedIcon />,
        },
        {
            label: 'Facebook',
            href: config.links.facebook,
            icon: <FacebookOutlinedIcon />,
        },
        {
            label: 'YouTube',
            href: config.links.youtube,
            icon: <YouTubeIcon />,
        },
        {
            label: 'Instagram',
            href: config.links.instagram,
            icon: <InstagramIcon />,
        },
        {
            label: 'Telegram',
            href: config.links.telegram,
            icon: <TelegramIcon />,
        },
        {
            label: 'Support',
            href: routes.pages.contact.path,
            icon: <SupportOutlinedIcon />,
        }
    ];

    return (
        <div>
            <Button
                variant="outlined" size="small" color="inherit"
                onClick={handleClick}
            >
                Support
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                slotProps={{
                    paper: {
                        sx: {
                            minWidth: 200,
                        }
                    }
                }}
            >
                {menuItems.map((item, index) => (
                    <MenuItem
                        key={index}
                        dense
                        onClick={() => {
                            if (item.href.startsWith('http')) {
                                window.open(item.href, '_blank');
                            } else {
                                router.push(item.href);
                            }

                            handleClose();
                        }}>
                        <ListItemIcon sx={{
                            mr: 0,
                        }}>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText>
                            {item.label}
                        </ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default SupportMenu;
