import React, { useState } from 'react';

import { useDataContextContext } from '@/layouts/CabinetLayout/context';
import { Box, Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

export default function BillingMenu() {

    // context
    const { contextData } = useDataContextContext();

    // states
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Button
                onClick={handleClick}
                variant="outlined" size="small" color="inherit"
                disableElevation
            >
                {contextData.balance} credits
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                slotProps={{
                    paper: {
                        sx: {
                            width: 230,
                            p: 5,
                        },
                    },
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                }}>
                    <Typography variant='h6'>Balance</Typography>
                    <Typography variant='body1'>{contextData.balance} credits</Typography>
                    <Divider />
                    <Button variant="contained" color="success" onClick={() => {
                        // commonEvents.paymentDialog.emit();
                        handleClose();
                    }}>
                        Add funds
                    </Button>
                </Box>
            </Popover>
        </>
    );
}
