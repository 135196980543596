import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

const AnimatedBackground = styled(Box)({
    pointerEvents: 'none',
    width: '100%',
    height: '100%',

    '&::before, &::after': {
        content: '""',
        position: 'absolute',
        borderRadius: '50%',
        filter: 'blur(120px)',
        animationTimingFunction: 'cubic-bezier(0.47, 0, 0.745, 0.715)',
        animationIterationCount: 'infinite',
    },
    '&::before': {
        background: 'conic-gradient(from 60deg, #ff6f91, #ff9671, #f9f871, #68d8d6, transparent)',
        width: '600px',
        height: '800px',
        top: 'calc(50vh - 300px)',
        left: 'calc(50vw - 300px)',
        animation: 'animateBefore 8s infinite',
    },
    '&::after': {
        background: 'conic-gradient(from 30deg, #9156ff, #7b72e9, #56ccf2, #42c58f, transparent)',
        width: '60vw',
        height: '60vw',
        top: 'calc(50vh - 30vw)',
        left: 'calc(50vw - 30vw)',
        animation: 'animateAfter 8s infinite',
    },
    '@keyframes animateBefore': {
        '0%': { transform: 'translateY(0)' },
        '50%': { transform: 'translateY(200px) scale(0.85)' },
        '100%': { transform: 'translateY(0)' },
    },
    '@keyframes animateAfter': {
        '0%': { transform: 'translateX(0)' },
        '50%': { transform: 'translateX(-300px) scale(1.1)' },
        '100%': { transform: 'translateX(0)' },
    },
});

interface AnimatedBackgroundProps {
    sx?: BoxProps['sx'];
}
const AnimatedBackgroundComponent = ({ sx }: AnimatedBackgroundProps) => {
    return (
        <Box sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden',
            zIndex: 0,
            ...sx,
        }}>
            <AnimatedBackground />
        </Box>
    )
};

export default AnimatedBackgroundComponent;
