class Routes {
	home = this.route('/~/insider')
	about = this.route('/~/insider/about')
	contact = this.route('/~/insider/contact')

	catalog = {
		index: this.route('/~/insider/catalog'),
		subcategory: this.route('/~/insider/catalog/[subcategory]', ['subcategory']),
	}

	idea = {
		index: this.route('/~/insider/idea'),
	}
	investors = {
		index: this.route('/~/insider/investors'),
	}

	request = this.route('/~/insider/request')

	cabinet = {
		requests: {
			index: this.route('/~/insider/cabinet/requests'),
			detail: this.route('/~/insider/cabinet/requests/detail/[uuid2]', ['uuid2']),
			edit: this.route('/~/insider/cabinet/requests/edit/[uuid2]', ['uuid2']),
		},
	}


	// Sample with parameters
	// projects = {
	// 	index: this.route('/projects'),
	// 	detail: this.route('/projects/[slug]', ['slug']),
	// };

	/**
	 * Helper method to define a route with or without parameters.
	 *
	 * @param path - The base path of the route.
	 * @param params - Optional placeholders for dynamic parts of the route.
	 * @returns Object containing `as` (for dynamic URLs) and `path`.
	 */
	route(path: string, params?: string[]) {
		if (params) {
			const paramPath = params.reduce((acc, param) => acc.replace(`[${param}]`, `:${param}`), path);
			return {
				as: (values: Record<string, string>) =>
					params.reduce((acc, param) => acc.replace(`:${param}`, values[param]), paramPath),
				path: paramPath,
			};
		}
		return { as: () => path, path };
	}
}

const routes = new Routes();


export default routes;
