import NextLink from 'next/link';
import { useState } from 'react';

import IconifyIcon from '@/components/IconifyIcon';
import MenuIcon from '@mui/icons-material/Menu';
import {
    Box, Divider, IconButton, List, ListItemButton, ListItemText
} from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { useLayoutContext } from '../context';

import type { MenuItem } from '../types';


const DrawerMenu = () => {

    // context
    const { config } = useLayoutContext();

    // state
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>) => {
        if ('key' in event && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    const renderMenu = (items: MenuItem[]) => {
        return (
            <List sx={{
                '& .MuiTypography-root': {
                    py: .5,
                    typography: 'h6',
                },
            }}>
                {items.map((item, index) => (
                    <ListItemButton
                        key={index}
                        dense
                        LinkComponent={NextLink}
                        href={item.item.path}>
                        {item.icon && (
                            <IconifyIcon icon={item.icon} sx={{
                                fontSize: 22,
                                color: 'text.secondary',
                                mr: 4,
                            }} />
                        )}
                        <ListItemText primary={item.name} />

                    </ListItemButton>
                ))}
            </List>
        );
    }

    return (
        <>
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
            >
                <MenuIcon />
            </IconButton>

            <SwipeableDrawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                PaperProps={{
                    sx: {
                        width: {
                            xs: '80vw',
                            sm: 300,
                        },
                    },
                }}
            >
                <Box
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    {renderMenu([config.menu.default.home])}
                    <Divider />
                    {renderMenu(config.menu.left)}
                    <Divider />
                    {renderMenu(config.menu.right)}
                </Box>
            </SwipeableDrawer>
        </>
    );
};

export default DrawerMenu;