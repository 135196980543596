
/**
 * Popular locales: en, es, fr, de, pt, it, ru
 */

const i18nConfig = {
    locales: ["en", "ru"],
    defaultLocale: "en",
}

module.exports = i18nConfig;