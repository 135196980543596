import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { memo } from 'react';

import IconifyIcon from '@/components/IconifyIcon';
import { Link } from '@/components/Link';
import useMedia from '@/hooks/useMedia';
import { useTranslate } from '@/hooks/useTranslate';
import UserMenu from '@/layouts/CustomLayout/Appbar/snippets/UserMenu';
import { Box } from '@mui/material';

import { useLayoutContext } from '../context';

import type { MenuItem } from '../types';

const AppbarDesktop = () => {
    // hooks
    const media = useMedia();
    const { t } = useTranslate('layout');

    // context
    const { config } = useLayoutContext();

    return (
        <Box sx={{
            position: 'sticky',
            top: 0,
            zIndex: theme => theme.zIndex.appBar,
        }}>
            <Box
                component="header"
                sx={{
                    py: 3,
                    pl: 5,
                    pr: 2,
                    height: theme => theme.mixins.appBarHeight,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,

                    borderBottom: 1,
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                }}>
                    <Box
                        component={NextLink}
                        href={config.menu.default.home.item.path}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            textDecoration: 'none',
                            color: 'inherit',
                            mr: 5,
                            typography: 'h6',
                        }}>
                        {config.app.name}
                        {/* <Box component="img"
                            src={config.static.logo.svg}
                            alt={config.app.name} sx={{
                                width: 110,
                            }} /> */}
                    </Box>

                    {media.up.md && (
                        <MenuItems items={config.menu.left} />
                    )}
                </Box>
                <Box flexGrow={1} />
                <MenuItems items={config.menu.right} />
                <UserMenu />
            </Box>
        </Box>
    )
}

interface MenuItemsProps {
    items: MenuItem[];
}
const MenuItems = ({ items }: MenuItemsProps) => {
    const router = useRouter();
    const { t } = useTranslate('layout');

    return (
        <Box sx={{
            display: 'flex',
        }}>
            {items.map((item, index) => {
                const isSelected = router.pathname.includes(item.item.path);

                return (
                    <Link key={index}
                        href={item.item.path}
                        variant='subtitle1'
                        sx={{
                            fontWeight: theme => theme.typography.fontWeightMedium,
                            color: 'text.primary',
                            borderBottom: 2,
                            borderColor: 'transparent',
                            transition: 'all 0.3s',
                            py: 3,
                            px: 2,

                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,

                            // if selected
                            ...(isSelected && {
                                borderColor: 'text.secondary',
                            }),
                        }}>
                        {item.icon && (
                            <IconifyIcon icon={item.icon} sx={{
                                fontSize: 18,
                                color: 'text.secondary',
                            }} />
                        )}
                        {item.name}
                    </Link>
                )
            })}
        </Box>
    )
}

export default memo(AppbarDesktop);