
import { ReactNode } from 'react';

// hooks
import { createContextBuilder } from '@/utils/context';

import { useDataContext } from './useDataContext';

// Create the context and hooks
const { Provider: DataContextProvider, useContextHook: useDataContextContext } = createContextBuilder(useDataContext);

// Export the hooks for usage
export { useDataContextContext };


interface Props {
  children: ReactNode;
}

export default function CombinedProvider({ children }: Props) {
  return (
    <DataContextProvider>
      {children}
    </DataContextProvider>
  );
}
