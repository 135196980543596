import { nanoid } from 'nanoid';

const cacheUuid = nanoid(10);
const cacheBuster = (url: string) => `${url}?cache=${cacheUuid}`;


const config = new class {
	app = {
		name: 'Unrealos',
	};

	analytics = {
		gaMeasurementId: 'G-TM6DHFCKF1',
		mixpanelToken: '5d03e27a6ee8406909172ca011eb3bd9',
		facebookPixelId: '',
	};

	theme = {
		mode: 'dark',
	};

	static = {
		favicons: {
			default: cacheBuster('/favicon.png'),
			active: cacheBuster('/active.png'),
			icon192: cacheBuster('/static/favicons/android-chrome-192x192.png'),
			icon384: cacheBuster('/static/favicons/android-chrome-384x384.png'),
			icon512: cacheBuster('/static/favicons/icon-512x512.png'),
		},
		logo: {
			svg: cacheBuster('/static/logo.svg'),
		},
		ogImage: cacheBuster('/static/og-image.jpg'),
	};

	assets = {
		countryFlag: (code: string) => {
			if (code == 'en') code = 'gb';
			return {
				svg: `/static/country-flags/svg/${code}.svg`,
				png: `/static/country-flags/png/${code}.png`,
			};
		},
	};

	github = {
		getStatic: (path: string) => `https://raw.githubusercontent.com/markolofsen/unrealos4-static/refs/heads/main/${path}`,
		editContent: (path: string) => `https://github.com/markolofsen/unrealos4/edit/main/${path}`,
	};

	links = {
		roadmap: 'https://aieditlab.featurebase.app/roadmap',
		feedback: 'https://aieditlab.featurebase.app',
		community: 'https://groups.google.com/g/aivideos',
		facebook: 'https://www.facebook.com/aieditlab',
		youtube: 'https://www.youtube.com/@aieditlab-com',
		instagram: 'https://www.instagram.com/aieditlab/',
		telegram: 'https://t.me/aieditlab',
	};

	contacts = {
		// whatsapp: 'https://wa.me/message/67C3BJ5Z3FWBP1',
		email: '',
		phone: '',
		telegram: 'https://t.me/unrealos',
		facebook: '',
		address: '315 Front St W, Toronto, Canada',
	};
}

export default config;
