
import { ReactNode } from 'react';

// hooks
import { useApp } from './app';
import { useAuth } from './auth';
import { createContextBuilder } from './utils/builder';

// Create the context and hooks
const { Provider: AppProvider, useContextHook: useAppContext } = createContextBuilder(useApp);
const { Provider: AuthProvider, useContextHook: useAuthContext } = createContextBuilder(useAuth);

// Export the hooks for usage
export { useAppContext, useAuthContext };


interface Props {
  children: ReactNode;
  appProps: any;
}

export default function CombinedProvider({ children, appProps }: Props) {

  // Remove Next.js specific props from the pageProps
  delete appProps?._nextI18Next
  delete appProps?._sentryTraceData
  delete appProps?._sentryBaggage

  return (
    <AppProvider appProps={appProps} hydrate>
      <AuthProvider>
        {children}
      </AuthProvider>
    </AppProvider>
  );
}
