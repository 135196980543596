import type { FormikErrors } from 'formik'; // for convenience, same shape
import type { FormField } from '../types/fields.types';

/**
 * In your old code, useAutoForm sets up initial values from "fields"
 * and also runs a quick local validation. This is a no-Formik approach
 * but we keep the same logic shape.
 */
export const useAutoForm = (
  fields: FormField[],
  propInitialValues?: Record<string, any>
) => {
  // Build initial values from fields + user-provided initialValues
  const getInitialValues = () => {
    const fromFields = fields.reduce((acc, field) => {
      acc[field.name] = field.value ?? null;
      return acc;
    }, {} as Record<string, any>);
    return { ...fromFields, ...propInitialValues };
  };

  // Optional local sync validation, ignoring "hidden" fields or condition-based ones
  const validate = (values: Record<string, any>): FormikErrors<any> => {
    const errors: Record<string, string> = {};

    for (const field of fields) {
      if (field.hidden) continue;

      // If there's a dependsOn + condition, skip validation if it fails
      const shouldValidate =
        !field.dependsOn ||
        !field.condition ||
        field.condition(values[field.dependsOn], values);

      if (shouldValidate && field.validation) {
        try {
          field.validation.validateSync(values[field.name]);
        } catch (error: any) {
          errors[field.name] = error.message;
        }
      }
    }

    return errors;
  };

  return {
    initialValues: getInitialValues(),
    validate,
  };
};
