import { useState } from 'react';

import StripeCheckout from '@/components/StripCheckout';
import { useAuthContext } from '@/context';
import { Box, Divider, InputAdornment, TextField, Typography } from '@mui/material';

import { useDataContextContext } from '../../context';

const Container = () => {
    // context
    const { contextData } = useDataContextContext();
    const { state: { userData } } = useAuthContext();
    const userId = userData?.id;

    if (!userId) return null;

    // State for USD value, initially set to 50
    const [usd, setUsd] = useState<number>(50);

    // Constants
    const ONE_USD_TO_CREDITS = contextData.usd_to_points || 0;
    const CREDITS_PER_5_SEC_VIDEO = contextData.credit_cost['5s'] || 0;

    // Calculated credits based on USD
    const credits = usd ? Math.round(usd * ONE_USD_TO_CREDITS) : 0;

    // Approximate number of 5-second videos
    const fiveSecondVideos = Math.floor(credits / CREDITS_PER_5_SEC_VIDEO);

    // Handler for updating USD
    const handleUsdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const usdValue = parseFloat(e.target.value) || 0;
        setUsd(usdValue);
    };

    const priceList = [
        {
            seconds: 5,
            credits: 10,
        },
        {
            seconds: 10,
            credits: 20,
        },
    ];

    return (
        <Box>
            <Box mb={5}>
                <Typography variant="body1" gutterBottom>
                    AI Video Generation
                </Typography>
                {priceList.map((item, index) => (
                    <Typography key={index} variant="body2" color="textSecondary">
                        {item.seconds} sec. video = {item.credits} credits.
                    </Typography>
                ))}
            </Box>
            <TextField
                label="USD"
                type="number"
                value={usd}
                onChange={handleUsdChange}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Credits"
                type="number"
                value={credits}
                disabled
                fullWidth
                margin="normal"
                slotProps={{
                    input: {
                        endAdornment: (
                            <InputAdornment position="end">
                                ~{fiveSecondVideos} videos
                            </InputAdornment>
                        ),
                    },
                }}
            />
            <Box mt={5}>
                <Divider />
                <StripeCheckout
                    body={{
                        userId: userId,
                        amount: usd,
                        currency: 'usd',
                        productName: 'Video credits',
                        quantity: 1,
                    }}
                />
            </Box>
        </Box>
    );
};

export default Container;
