import { Alert, AlertTitle, Box, Button } from '@mui/material';
import React, { memo, useEffect } from 'react';

import { useAutoForm } from './hooks/useAutoForm';


import env from '@/core/env';
import { useTranslate } from '@/hooks/useTranslate';
import { logger } from '@/utils/logger';
import { ReCaptchaProvider, useReCaptcha } from 'next-recaptcha-v3';
import { useFormContext } from './context';
import Fields from './fields';
import type { AutoFormProps } from './types/form.types';

/**
 * Our "AutoForm" component, replicating the old API:
 *   - config, fields, onSubmit, validationSchema, etc.
 *   - Optionally "children" as a render-prop.
 */
const AutoForm = memo((props: AutoFormProps) => {
  const {
    config,
    fields,
    onSubmit,
    initialValues: propInitialValues,
    validationSchema,
    submitButton,
    validateOnRender,
    children,
    ...rest
  } = props;

  const { t } = useTranslate('common');

  const {
    formRef,
    isSubmitting,
    setSubmitting,
    currentValues,
    setCurrentValues,
    currentErrors,
    setCurrentErrors,
    updateStoreValues,
    setErrors,
    errors,
    saveToLocalStorage,
    loadFromLocalStorage,
    clearLocalStorage
  } = useFormContext();

  const { initialValues, validate } = useAutoForm(fields, propInitialValues);
  const { executeRecaptcha } = useReCaptcha();

  // Load from localStorage on mount if localStorageKey is provided
  useEffect(() => {
    if (config?.localStorageKey) {
      const storedValues = loadFromLocalStorage(config.localStorageKey);
      if (Object.keys(storedValues).length > 0) {
        setCurrentValues({ ...initialValues, ...storedValues });
      } else {
        setCurrentValues(initialValues);
      }
    } else {
      setCurrentValues(initialValues);
    }
  }, []);

  // Save to localStorage when values change
  useEffect(() => {
    if (config?.localStorageKey && Object.keys(currentValues).length > 0) {
      saveToLocalStorage(config.localStorageKey, currentValues);
    }
  }, [currentValues]);

  // If "validateOnRender" is true, do an immediate validation
  useEffect(() => {
    if (validateOnRender) {
      (async () => {
        const errs = await validate(currentValues);
        setCurrentErrors(errs);
        setErrors(errs);
      })();
    }
  }, [currentValues]);

  // The final onSubmit for the <form>
  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errs = await validate(currentValues);
    setCurrentErrors(errs);
    setErrors(errs);

    const hasErrors = Object.keys(errs).length > 0;

    if (!hasErrors) {
      let submissionValues = { ...currentValues };
      if (config?.recaptcha) {
        try {
          const token = await executeRecaptcha('form_submit');
          if (token) submissionValues.recaptcha_token = token;
        } catch (err) {
          console.error('Error getting reCAPTCHA token:', err);
        }
      }

      setSubmitting(true);
      await onSubmit(submissionValues);

      // Clear localStorage after successful submit if localStorageKey was provided
      if (config?.localStorageKey) {
        clearLocalStorage(config.localStorageKey);
      }

      setSubmitting(false);
    }
  };

  // A helper function to set field value
  const setFieldValue = (name: string, value: any) => {
    setCurrentValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // The function to render fields (or pass to children)
  const renderFields = () => {
    const getField = (fieldName: string) => {
      const field = fields.find((f) => f.name === fieldName);
      if (!field) {
        return (
          <Box sx={{ color: 'error.main', typography: 'caption' }}>
            Field not found: {fieldName}
          </Box>
        );
      }
      return (
        <Fields
          key={field.name}
          field={field}
          config={config}
          value={currentValues[field.name]}
          errorText={currentErrors[field.name] as string}
          onChange={(val) => {
            setFieldValue(field.name, val);
            updateStoreValues(field.name, val);
          }}
          onBlur={() => { }}
        />
      );
    };

    const getFieldData = (fieldName: string) => fields.find((f) => f.name === fieldName);

    // If user passed a render-prop
    if (typeof children === 'function') {
      return children({ getField, getFieldData });
    }

    // Otherwise just map out the fields
    return fields.map((field) => {
      // Condition checks for hidden or dependsOn
      if (field.hidden) return null;
      if (
        field.dependsOn &&
        field.condition &&
        !field.condition(currentValues[field.dependsOn], currentValues)
      ) {
        return null;
      }

      return getField(field.name);
    });
  };

  // Render other errors
  const renderNoneFieldsErrors = () => {
    const { t } = useTranslate('common');

    const names = fields.map((field) => field.name);
    const otherErrors: { key: string, value: string }[] = [];
    for (const key in errors) {
      if (!names.includes(key)) {
        // convert slug to human readable (capitalize first letter)
        const humanReadableKey = key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
        otherErrors.push({ key: humanReadableKey, value: errors[key] as string });
      }
    }

    if (otherErrors.length === 0) return null;

    return (
      <Alert severity="error" sx={{ mb: 5 }}>
        <AlertTitle variant='h6'>
          {t('form.errors.title')}
        </AlertTitle>
        <Box sx={{
          typography: 'caption',
        }}>
          {otherErrors.map((item, index) => (
            <Box key={index}>
              {item.key}: {item.value}
            </Box>
          ))}
        </Box>
      </Alert>
    )
  };

  return (
    <Box
      component="form"
      onSubmit={onFormSubmit}
      ref={formRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        width: '100%',
        ...rest?.sx,
      }}
    >

      {renderNoneFieldsErrors()}
      {renderFields()}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        size={config?.size === 'medium' ? 'large' : 'medium'}
        {...submitButton}
      >
        {submitButton?.children || t('form.submit')}
      </Button>
    </Box>
  );
});

export default (props: AutoFormProps) => (
  <ReCaptchaProvider
    reCaptchaKey={env.GOOGLE_RECAPTCHA_SITE_KEY}
    async       // top-level props
    defer       // passed to <Script> internally
    onError={(e) => {
      logger.error(e)
    }}
  >
    <AutoForm {...props} />
  </ReCaptchaProvider>
);
