import React from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import type { CheckboxFieldType } from '../types/fields.types';
import type { FormConfig } from '../types/form.types';
import { useFormContext } from '../context';

interface Props {
  config?: FormConfig;
  field: CheckboxFieldType;
  value: boolean;
  errorText?: string;
  onChange: (value: boolean) => void;
  onBlur: (event: React.FocusEvent<any>) => void;
}

const CheckboxRenderer = ({
  config,
  field,
  value,
  errorText,
  onChange,
  onBlur,
}: Props) => {
  const { isSubmitting } = useFormContext();
  const isDisabled = field.props?.disabled || isSubmitting;
  const helperText = field.helperText;

  return (
    <Box pl={2.5}>
      <FormControlLabel
        disabled={isDisabled}
        control={
          <Checkbox
            name={field.name}
            checked={!!value}
            onChange={(e, checked) => onChange(checked)}
            onBlur={onBlur}
            size={config?.size || 'small'}
          />
        }
        label={field.label}
      />
      {helperText && (
        <Typography variant="caption" color="text.secondary">
          {helperText}
        </Typography>
      )}
      {errorText && (
        <Typography variant="caption" color="error">
          {errorText}
        </Typography>
      )}
    </Box>
  );
};

export default CheckboxRenderer;
