import Head from 'next/head';

import { useAppContext } from '@/context';
import cfg from '@/core/config';
import env from '@/core/env';

const SeoData = () => {

  // context
  const { manager: { appProps, pageUrl } } = useAppContext()

  // computed
  const { config } = appProps || {}
  const description = config?.seo?.description
  const image = config?.seo?.image || cfg.static.ogImage

  const title = (() => {
    const str = config?.seo?.title || cfg.app.name
    if (!str) return cfg.app.name
    return str
  })()

  const canonicalUrl = pageUrl ? `${env.FRONTEND_URL}${pageUrl}` : null

  return (
    <Head>

      {title ? (
        <>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </>
      ) : ''}

      {description ? (
        <meta name="description" content={description} />
      ) : ''}

      <meta property="og:image" content={image} />

      {canonicalUrl ? (
        <link rel="canonical" href={canonicalUrl} />
      ) : ''}


    </Head>
  )
}


export default SeoData;