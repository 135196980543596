const IS_DEV = process.env.NODE_ENV === 'development';

const env = new class {
	FRONTEND_URL: string = process.env.FRONTEND_URL as string;
	DJANGO_URL: string = process.env.DJANGO_URL as string;
	MOCK_API: boolean = process.env.MOCK_API === 'true';

	GOOGLE_MAPS_API_KEY: string = process.env.GOOGLE_MAPS_API_KEY as string;
	GOOGLE_RECAPTCHA_SITE_KEY: string = process.env.GOOGLE_RECAPTCHA_SITE_KEY as string;
	GOOGLE_OAUTH_CLIENT_ID: string = process.env.GOOGLE_OAUTH_CLIENT_ID as string;
	STRIPE_PUBLIC_KEY: string = process.env.STRIPE_PUBLIC_KEY as string;
	BACKEND_SECRET: string = process.env.BACKEND_SECRET as string;

	isDev = IS_DEV;
	isProd = !IS_DEV;
	isMock = process.env.MOCK_MODE === 'true';
	isStorybook = process.env.STORYBOOK === 'true';

	// getFrontendUrl = (postfix?: string | undefined) => {
	// 	if (!postfix) return '';

	// 	let frontendUrl = this.FRONTEND_URL;
	// 	if (typeof window !== 'undefined') {
	// 		frontendUrl = window.location.origin;
	// 	}
	// 	return `${frontendUrl}${postfix}`;
	// }
}

export default env;
