import { useTranslate } from '@/hooks/useTranslate';
import CustomLayout from '@/layouts/CustomLayout';

import config from '../core/config';
import appRoutes from '../core/routes';

interface Props {
    children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
    const { t } = useTranslate('insider');

    return (
        <CustomLayout
            config={{
                app: {
                    name: config.app.name,
                    description: 'Discover the best AI startups',
                },
                menu: {
                    default: {
                        home: {
                            name: t('common.home'),
                            item: appRoutes.home,
                        },
                    },
                    left: [
                        {
                            name: 'AI for Business',
                            item: appRoutes.services.ai,
                        },
                        {
                            name: 'SaaS/PaaS',
                            item: appRoutes.services.cloud,
                        },
                        {
                            name: 'Real-Estate',
                            item: appRoutes.services.realEstate,
                        },
                        {
                            name: 'Automotive',
                            item: appRoutes.services.automotive,
                        },
                        {
                            name: '3D',
                            item: appRoutes.services.unrealEngine,
                        },
                    ],
                    right: [
                        // {
                        //     name: 'Lab',
                        //     href: 'https://lab.unrealos.com',
                        //     icon: <ScienceOutlinedIcon fontSize='small' />,
                        // },
                        {
                            name: 'Projects',
                            item: appRoutes.projects.index,
                            icon: 'ic:twotone-work-outline',
                        },
                        {
                            name: 'Solutions',
                            item: appRoutes.solutions.index,
                            icon: 'hugeicons:ai-idea',
                        },
                        {
                            name: 'About',
                            item: appRoutes.about,
                            icon: 'icons8:info',
                        },
                        {
                            name: 'Contact',
                            item: appRoutes.contact,
                            icon: 'iconoir:message-text',
                        }
                    ],
                },
            }}>
            {children}
        </CustomLayout>
    )
}

export default Layout;