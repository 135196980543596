import { memo } from 'react';
import { RenderPropSticky } from 'react-sticky-el';

import useMedia from '@/hooks/useMedia';
import type { CommonProps, RenderFuncArgs } from 'react-sticky-el/lib/types';

interface StickyBoxProps {
  children: (args: RenderFuncArgs) => React.ReactNode;
  boundaryElement?: string;
  config?: Partial<CommonProps>;
  zIndex?: number;
  disableMobile?: boolean;
}

const StickyBox = ({
  children,
  boundaryElement,
  config,
  zIndex,
  disableMobile,
}: StickyBoxProps) => {
  const media = useMedia();

  if (disableMobile && media.down.md) {
    return children({
      holderRef: () => { },
      wrapperRef: () => { },
      isFixed: false,
      wrapperStyles: {},
      holderStyles: {},
    })
  }

  return (
    <RenderPropSticky
      boundaryElement={boundaryElement}
      hideOnBoundaryHit={false}
      {...config}
    >
      {({ isFixed, wrapperStyles, wrapperRef, holderStyles, holderRef }) => (
        <div ref={holderRef} style={{ zIndex, ...holderStyles }}>
          <div ref={wrapperRef} style={wrapperStyles}>
            {children({ isFixed, wrapperStyles, wrapperRef, holderStyles, holderRef })}
          </div>
        </div>
      )}
    </RenderPropSticky>
  );
};

export default memo(StickyBox);
