import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

const SnackbarInitializer = () => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        if (!window.common) {
            window.common = {} as CommonInterface;
        }

        window.common.notify = ({ message, variant, anchorOrigin, autoHideDuration }) => {
            enqueueSnackbar(message, {
                variant: variant,
                autoHideDuration,
                anchorOrigin,
            });
        };
    }

    return <></>;
};

export default SnackbarInitializer;
