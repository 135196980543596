
import { ThemeOptions } from '@mui/material';

// ** Theme Config Imports
const systemFont = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Helvetica Neue',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
];

const fontFamily = ['GeistSans', ...systemFont].map((font) => (font.includes(' ') ? `"${font}"` : font)).join(',');


export const typography: ThemeOptions['typography'] = {
  fontFamily,
  fontWeightBold: 700,
  h1: {
    fontSize: '2.7rem',
    fontWeight: 600,
  },
  h2: {
    fontSize: '2rem',
    fontWeight: 600,
  },
  h3: {
    fontSize: '1.8rem',
    fontWeight: 600,
  },
  h4: {
    fontSize: '1.5em',
    fontWeight: 600,
  },
  h5: {
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  button: {
    textTransform: 'none',
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  body1: {
    fontSize: '1.125rem',
    fontWeight: 400,
    lineHeight: 1.3,
  },
  body2: {
    fontSize: '0.938rem',
    fontWeight: 400,
    lineHeight: 1.3,
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.4,
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.4,
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.3,
    display: 'block',
  },
};
