import { ReactNode } from 'react';

// hooks
import { createContextBuilder } from '@/utils/context';

import { useLayout } from './useLayout';

// Create the context and hooks
const { Provider: LayoutProvider, useContextHook: useLayoutContext } = createContextBuilder(useLayout);

// Export the hooks for usage
export { useLayoutContext };

interface Props {
  children: ReactNode;
}

export default function CombinedProvider({ children }: Props) {
  return (
    <LayoutProvider>
      {children}
    </LayoutProvider>
  );
}
