import { useRouter } from 'next/router';
import { memo } from 'react';
import { useNetworkState } from 'react-use';

import { ButtonLink } from '@/components/Link';
import { useAuthContext } from '@/context';
import routes from '@/core/routes';
// snippets
import { useTranslate } from '@/hooks/useTranslate';
import ToolbarMenu from '@/snippets/ToolbarMenu';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, Typography } from '@mui/material';

import Switchers from './Switchers';

const UserMenu = () => {
    // context
    const authContext = useAuthContext();
    const { t } = useTranslate('layout');

    // hooks
    const router = useRouter();
    const network = useNetworkState();

    // icons
    // const ConnectionIcon = network.online ? CloudOutlinedIcon : CloudOffOutlinedIcon;

    const menuItems = [
        {
            label: t('menu.user.settings'),
            icon: SettingsOutlinedIcon,
            onClick: () => {
                // commonEvents.settingsDialog.emit();
            },
        },
        // {
        //     label: 'Support Center',
        //     icon: HelpOutlineOutlinedIcon,
        //     onClick: () => {
        //         // events.common.supportCenter.emit();
        //     },
        // },
        {
            label: t('menu.user.report_issue'),
            icon: BugReportOutlinedIcon,
            onClick: () => {
                const url = routes.pages.contact.path;
                router.push(url);
            },
        },
        {
            label: t('menu.user.logout.title'),
            icon: LogoutOutlinedIcon,
            onClick: async () => {
                const confirmed = await window.common.confirm({
                    title: t('menu.user.logout.title'),
                    message: t('menu.user.logout.message'),
                    confirmButton: {
                        color: 'error',
                        label: t('menu.user.logout.confirm'),
                    }
                });

                if (confirmed) {
                    authContext.manager.handleLogout();
                }
            },
        },
    ];

    return (
        <div>
            <ToolbarMenu
                size='small'
                icon={() => {
                    return (
                        <AccountCircleOutlinedIcon sx={{
                            fontSize: 24,
                        }} />
                    )
                }}
                menuItems={menuItems}
                position="right"
                tooltipLabel={t('menu.user.tooltip')}
                sx={{
                    color: 'text.primary',
                    p: 2,
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 2,
                    }}>
                        <Typography variant="subtitle2">
                            {t('menu.network')}
                        </Typography>
                        <Typography variant="subtitle2" sx={{
                            color: network.online ? 'success.main' : 'error.main',
                        }}>
                            {network.online ? t('menu.status.online') : t('menu.status.offline')}
                        </Typography>
                    </Box>

                    <Switchers />
                </Box>

            </ToolbarMenu>
        </div>
    );
}

const UserMenuAuth = () => {
    const { manager } = useAuthContext();
    const { t } = useTranslate('auth_common');

    if (manager.isLogged) {
        return <UserMenu />
    }

    return (
        <Box>
            <ButtonLink
                href={routes.auth.login.path}
                variant="contained"
                data-button-gradient
                sx={{
                    typography: 'button',
                    '&.MuiButton-contained': {
                        px: 3,
                        py: 1,
                    },
                }}
            >
                {t('login.submit')}
            </ButtonLink>
        </Box>
    )

}

export default memo(UserMenuAuth);
