import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import { useFormContext } from '../context';
import type { SelectFieldType } from '../types/fields.types';
import type { FormConfig } from '../types/form.types';

interface Props {
  config?: FormConfig;
  field: SelectFieldType;
  value: string | null;
  errorText?: string;
  onChange: (value: string) => void;
  onBlur: (event: React.FocusEvent<any>) => void;
}

const SelectField = ({
  config,
  field,
  value,
  errorText,
  onChange,
  onBlur,
}: Props) => {
  const { isSubmitting } = useFormContext();
  const placeholder = field.props?.placeholder;
  const isDisabled = field.props?.disabled || isSubmitting;

  return (
    <FormControl
      size={config?.size || 'small'}
      fullWidth
      error={Boolean(errorText)}
      disabled={isDisabled}
    >
      <InputLabel>{field.label}</InputLabel>
      <Select
        value={value || ''}
        onChange={(e) => onChange(e.target.value as string)}
        onBlur={onBlur}
        label={field.label}
      >
        {placeholder && (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        )}
        {field.options.map((opt) => (
          <MenuItem key={opt.value} value={opt.value}>
            {field.renderOption ? field.renderOption(opt) : opt.label}
          </MenuItem>
        ))}
      </Select>
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectField;
