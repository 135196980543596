import { memo } from 'react';

import PaymentDialog from './PaymentDialog';

// import SettingsDialog from './SettingsDialog';

const Common = memo(() => {
    return (
        <>
            <PaymentDialog />
            {/* <SettingsDialog /> */}
        </>
    )
})

export default Common;