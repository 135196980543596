
import { ReactNode } from 'react';

import { createContextBuilder } from '@/utils/context';

// hooks
import { useLayout } from './useLayout';

import type { LayoutConfig } from '../types';

// Create the context and hooks
const { Provider: LayoutProvider, useContextHook: useLayoutContext } = createContextBuilder(useLayout);

// Export the hooks for usage
export { useLayoutContext };


interface Props {
  children: ReactNode;
  config: LayoutConfig;
}

export default function CombinedProvider({ children, config }: Props) {

  return (
    <LayoutProvider config={config} hydrate>
      {children}
    </LayoutProvider>
  );
}
