import MyLocationIcon from '@mui/icons-material/MyLocation';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { Autocomplete, Box, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';
import { useFormContext } from '../context';
import type { GoogleAddressFieldType, LocationSelectData } from '../types/fields.types';
import type { FormConfig } from '../types/form.types';

interface GoogleAddressFieldRendererProps {
    config?: FormConfig;
    field: GoogleAddressFieldType;
    value: LocationSelectData | null;
    errorText?: string;
    onChange: (params: LocationSelectData) => void;
    onBlur: (event: React.FocusEvent<any>) => void;
}

const formatAddress = (result: google.maps.GeocoderResult) => {
    const components = result.address_components;
    const getComponent = (type: string) =>
        components.find(c => c.types.includes(type))?.short_name || '';

    const parts = {
        streetNumber: getComponent('street_number'),
        street: getComponent('route'),
        district: getComponent('sublocality'),
        city: getComponent('locality'),
        area: getComponent('administrative_area_level_2'),
        region: getComponent('administrative_area_level_1'),
        country: getComponent('country'),
        postalCode: getComponent('postal_code'),
    };

    // Build address from components
    const addressParts = [
        parts.streetNumber && parts.street ? `${parts.streetNumber} ${parts.street}` : parts.street,
        parts.district,
        parts.city,
        parts.area !== parts.city ? parts.area : '',
        parts.region,
        parts.country
    ].filter(Boolean).join(', ');

    return addressParts || result.formatted_address;
};

const useReverseGeocode = () => {
    return useCallback(async (latitude: number, longitude: number) => {
        try {
            const results = await getGeocode({
                location: { lat: latitude, lng: longitude }
            });

            if (results[0]) {
                return {
                    address: formatAddress(results[0])
                };
            }
            throw new Error('No results found');
        } catch (error) {
            console.error('Error reverse geocoding:', error);
            throw error;
        }
    }, []);
};

const GoogleAddressFieldRenderer: React.FC<GoogleAddressFieldRendererProps> = ({
    config,
    field,
    value,
    errorText,
    onChange,
    onBlur,
}) => {
    const { isSubmitting } = useFormContext();
    const [inputValue, setInputValue] = useState('');
    const [isLocating, setIsLocating] = useState(false);
    const isDisabled = field.props?.disabled || isSubmitting;

    const {
        ready,
        suggestions: { status, data },
        setValue: setSearchValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {

            // place types are specified in the request, which exceeds the limit (5).
            // types: [
            //     'establishment',
            //     'lodging',
            //     'point_of_interest',
            //     'locality',
            //     'geocode'
            // ],
        },
        debounce: 200,
    });

    const reverseGeocode = useReverseGeocode();

    useEffect(() => {
        setInputValue(value?.address || '');
        setSearchValue(value?.address || '', false);
    }, [value, setSearchValue]);

    // Add effect to handle coordinates without address
    useEffect(() => {
        const fetchAddress = async () => {
            if (value && value.latitude && value.longitude && !value.address) {
                try {
                    const { address } = await reverseGeocode(value.latitude, value.longitude);

                    // Update form with complete location data
                    onChange({
                        ...value,
                        address
                    });

                    setInputValue(address);
                    setSearchValue(address, false);

                    // Call onLocationSelect if provided
                    if (field.props?.onLocationSelect) {
                        field.props.onLocationSelect({
                            ...value,
                            address
                        });
                    }
                } catch (error) {
                    console.error('Error fetching address:', error);
                }
            }
        };

        fetchAddress();
    }, [value?.latitude, value?.longitude, value?.address]);

    const handleSelect = async (description: string | null) => {
        if (!description || typeof description !== 'string') {
            onChange({
                address: '',
                latitude: 0,
                longitude: 0
            });
            return;
        }

        setSearchValue(description, false);
        setInputValue(description);
        clearSuggestions();

        try {
            const results = await getGeocode({ address: description });
            if (!results.length) throw new Error("No results found");

            const { lat, lng } = await getLatLng(results[0]);
            const formattedAddress = formatAddress(results[0]);

            const selectedLocation: LocationSelectData = {
                address: formattedAddress,
                latitude: lat,
                longitude: lng
            };

            onChange(selectedLocation);

            if (field.props?.onLocationSelect) {
                field.props.onLocationSelect(selectedLocation);
            }
        } catch (error) {
            console.error('Error getting geocode:', error);
        }
    };


    const handleInputChange = (_: any, newInputValue: string) => {
        setInputValue(newInputValue);
        setSearchValue(newInputValue);
    };

    const handleDetectLocation = async () => {
        if (!navigator.geolocation) {
            console.error('Geolocation is not supported by your browser');
            return;
        }

        setIsLocating(true);
        try {
            const position = await new Promise<GeolocationPosition>((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject, {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                });
            });

            const { latitude, longitude } = position.coords;
            const results = await getGeocode({
                location: { lat: latitude, lng: longitude }
            });

            if (results[0]) {
                const formattedAddress = formatAddress(results[0]);

                const locationData = {
                    address: formattedAddress,
                    latitude,
                    longitude
                };

                // Update the form value first
                onChange(locationData);

                // Then update the internal states
                setInputValue(formattedAddress);
                setSearchValue(formattedAddress, false);
                clearSuggestions();

                // Finally trigger the location select callback
                if (field.props?.onLocationSelect) {
                    field.props.onLocationSelect(locationData);
                }
            }
        } catch (error) {
            console.error('Error detecting location:', error);
        } finally {
            setIsLocating(false);
        }
    };

    return (
        <>
            <Autocomplete
                freeSolo
                size={config?.size || 'small'}
                value={value?.address || ''}
                onChange={(_, newValue) => handleSelect(newValue)}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                options={status === 'OK' ? data.map(suggestion => suggestion.description) : []}
                disabled={!ready || isDisabled}
                renderOption={(props, option, { index }) => (
                    <Box component="li"
                        data-option
                        {...props}
                        key={index}
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            typography: 'subtitle2',
                        }}>
                        <PlaceOutlinedIcon fontSize='small' sx={{
                            color: 'text.secondary',
                        }} />
                        {option}
                    </Box>
                )}
                clearIcon={false}
                onBlur={(e) => {
                    clearSuggestions();
                    onBlur(e);
                }}
                sx={{
                    '&.MuiAutocomplete-root': {
                        '& .MuiOutlinedInput-root': {
                            pr: 2
                        },
                    },

                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        name={field.name}
                        label={field.label}
                        error={Boolean(errorText)}
                        helperText={errorText}
                        slotProps={{
                            input: {
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        <InputAdornment position="end">
                                            <Tooltip title="Detect my location" arrow placement="left" disableTouchListener>
                                                <IconButton
                                                    onClick={handleDetectLocation}
                                                    disabled={isLocating || isDisabled}
                                                    size="small"
                                                    title="Detect my location"
                                                >
                                                    <MyLocationIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    </>
                                ),
                            }
                        }}
                        {...field.props}
                    />
                )}
            />
        </>
    );
};

export default GoogleAddressFieldRenderer;
