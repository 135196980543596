import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { memo } from 'react';

import { Typography } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

import type { MenuItem } from './types';

interface IconBoxProps extends BoxProps {
    item: MenuItem;
}
export const IconBox = ({ item, ...props }: IconBoxProps) => {

    // hooks
    const router = useRouter();

    // computed
    const isActive = item.mask ? router.pathname.includes(`/${item.mask}`) : false;

    return (
        <Box sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            height: theme => theme.mixins.appBarHeight,
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            overflow: 'hidden',
            '&:hover': {
                bgcolor: 'action.selected',
            },
            '& > [data-icon]': {
                flexShrink: 0,
                flexBasis: theme => theme.mixins.appBarHeight,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& svg': {
                    fontSize: 24,
                    color: 'text.secondary',
                },
            },
            ...props.sx,
            ...(isActive && {
                bgcolor: 'action.selected',
            }),
        }}>
            {isActive && (
                <Box sx={{
                    pointerEvents: 'none',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: 4,
                    bgcolor: 'primary.main',
                }} />
            )}
            <Box data-icon>
                {item.icon}
            </Box>
            <Typography variant="subtitle2" color="text.primary">
                {item.label}
            </Typography>
        </Box>
    )
}

interface MenuListProps {
    items: MenuItem[];
}
const MenuList = memo(({ items }: MenuListProps) => {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1px'
        }}>

            {items.map((item, index) => {
                let boxProps: any = {
                    onClick: item.onClick,
                }

                if (item.href) {
                    boxProps = {
                        component: NextLink,
                        href: item.href,
                        sx: {
                            textDecoration: 'none',
                            color: 'inherit',
                        }
                    }
                }
                return (
                    <Box key={index} {...boxProps}>
                        <IconBox item={item} />
                    </Box>
                )
            })}

        </Box>
    )
});


export default MenuList;