import routes from '@/core/routes';

type MenuItem = {
    name: string;
    href: string;
};
export const industries: MenuItem[] = [
    // {
    //     name: 'AI for Business',
    //     href: routes.services.ai,
    // },
    // {
    //     name: 'SaaS/PaaS',
    //     href: routes.services.cloud,
    // },
    // {
    //     name: 'Real-Estate',
    //     href: routes.services.realEstate,
    // },
    // {
    //     name: 'Automotive',
    //     href: routes.services.automotive,
    // },
    // {
    //     name: '3D',
    //     href: routes.services.unrealEngine,
    // },
];

export const menu: MenuItem[] = [
    // {
    //     name: 'Projects',
    //     href: routes.projects.index,
    // },
    // {
    //     name: 'Solutions',
    //     href: routes.solutions.index,
    // },
    // {
    //     name: 'About',
    //     href: routes.pages.about.path,
    // },
    {
        name: 'Support',
        href: routes.pages.contact.path,
    }
];