import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import NextApi from '@/api/methods/next';
import IconifyIcon from '@/components/IconifyIcon';
import { useAuthContext } from '@/context';
import env from '@/core/env';
import routes from '@/core/routes';
import { useTranslate } from '@/hooks/useTranslate';
import { logger } from '@/utils/logger';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';

const GoogleAuth = () => {

    // hooks
    const router = useRouter();
    const { t } = useTranslate('auth_common');

    // context
    const { manager, backUrl } = useAuthContext();

    // states
    const [preloader, setPreloader] = useState(false);

    // computed
    const code = router.query.code as string;

    // auth code
    useEffect(() => {
        if (!code) return;
        authCode(code);
    }, [code]);

    const buildRedirectUrl = () => {
        if (typeof window === 'undefined') return '';
        const currentUrl = window.location.href;
        const { host, protocol } = new URL(currentUrl);
        return `${protocol}//${host}${routes.auth.login.path}`;
    }
    const redirect_uri = buildRedirectUrl();

    const authCode = async (code: string) => {
        setPreloader(true);

        await NextApi.googleAuth(code, redirect_uri).then((res) => {
            if (res.status === 200) {
                const { access, refresh } = res.data;
                if (!access || !refresh) {
                    alert('Failed to login with Google');
                    return;
                }
                manager.setTokenData(access, refresh);
                manager.redirectIfLogged();

            } else {
                window.common.alert({
                    title: 'Error',
                    message: 'Failed to login with Google',
                })
            }
        }).catch((err) => {
            logger.error(err);

            window.common.alert({
                title: 'Error',
                message: 'Failed to login with Google',
            })
        });

        setPreloader(false);
    }

    const googleLogin = useGoogleLogin({
        flow: 'auth-code',
        ux_mode: 'redirect',
        select_account: false,
        redirect_uri,
        scope: 'https://www.googleapis.com/auth/userinfo.email openid https://www.googleapis.com/auth/userinfo.profile',
    });

    const login = () => {
        backUrl.save();
        googleLogin();
    };

    return (
        <Box>
            <LoadingButton
                startIcon={<IconifyIcon icon="flat-color-icons:google" />}
                fullWidth
                size="large"
                color="secondary"
                variant="outlined"
                onClick={login}
                loading={preloader}
            >
                {t('login.google')}
            </LoadingButton>
        </Box>
    );
};

export default function GoogleAuthWrapper() {
    return (
        <GoogleOAuthProvider clientId={env.GOOGLE_OAUTH_CLIENT_ID}>
            <GoogleAuth />
        </GoogleOAuthProvider>
    )
}
