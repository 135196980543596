import StickyBox from '@/components/StickyBox';
import { Box, Container } from '@mui/material';

import Menu from './Menu';

interface AdminLayoutProps {
    children: React.ReactNode
}
const AdminLayout = ({ children }: AdminLayoutProps) => {
    return (
        <Box
            id="admin-layout"
            sx={{
                display: 'grid',
                gridTemplateColumns: '230px 1fr',
                minHeight: '100vh',
            }}>
            <Box sx={{
                bgcolor: 'action.hover',
            }}>
                <StickyBox boundaryElement='#admin-layout'>
                    {({ isFixed }) => (
                        <Menu />
                    )}
                </StickyBox>
            </Box>
            <Box py={10}>
                <Container maxWidth="lg">
                    {children}
                </Container>
            </Box>
        </Box>
    )
}

export default AdminLayout