import Head from 'next/head';
import { useEffect } from 'react';

import NextApi from '@/api/methods/next';
import { useAppContext, useAuthContext } from '@/context';
import env from '@/core/env';
import routes from '@/core/routes';
import { logger } from '@/utils/logger';

const GoogleTapLogin = () => {
    const { manager } = useAuthContext();
    const { manager: { appProps } } = useAppContext();

    useEffect(() => {
        if (!appProps.config?.oneTapLogin) return;

        if (manager.isLogged) {
            logger.info("User is already logged in. Skipping Google One Tap initialization.");
            return;
        }

        const initializeOneTap = () => {
            try {
                window.google.accounts.id.initialize({
                    client_id: env.GOOGLE_OAUTH_CLIENT_ID,
                    callback: handleOneTapResponse,
                    // auto_select: env.isDev ? false : true,
                    auto_select: false,
                    cancel_on_tap_outside: true,
                    useFedCM: true,
                });

                window.google.accounts.id.prompt((notification) => {
                    if (notification.isSkippedMoment()) {
                        logger.info("Google One Tap skipped.");
                    }
                    if (notification.isDismissedMoment()) {
                        logger.info("Google One Tap dismissed.");
                    }
                });
            } catch (error) {
                console.error("Error initializing Google One Tap:", error);
            }
        };

        if (window.google && window.google.accounts) {
            initializeOneTap();
        } else {
            logger.error("Google Identity Services library not loaded.");
        }
    }, [manager.isLogged]);

    const buildRedirectUrl = () => {
        if (typeof window === 'undefined') return '';
        const currentUrl = window.location.href;
        const { host, protocol } = new URL(currentUrl);
        return `${protocol}//${host}${routes.auth.login.path}`;
    }

    const handleOneTapResponse = async ({ credential }) => {
        if (!credential) {
            logger.error("Error authenticating with Google One Tap: Missing credential.");
            return;
        }

        const redirect_uri = buildRedirectUrl();
        await NextApi.googleOneTapAuth(credential, redirect_uri).then(res => {
            if (res.status === 200) {
                const { access, refresh } = res.data;

                if (!access || !refresh) {
                    logger.error("Error authenticating with backend: Missing tokens.");
                    return;
                }

                manager.setTokenData(access, refresh);
                manager.redirectIfLogged();
            } else {
                logger.error("Error authenticating with backend:", res.statusText);
            }
        }).catch((error) => {
            logger.error("Error authenticating with backend:", error);
        });
    };

    return (
        <>
            <Head>
                <script src="https://accounts.google.com/gsi/client" async defer></script>
            </Head>
        </>
    );
};

export default GoogleTapLogin;