import React from 'react';

// context
import { useAppContext } from '@/context';

// components
import SwitcherWrapper from './SwitcherWrapper';

const ThemeToggle = () => {
  const { manager, state: { theme } } = useAppContext();

  const handleThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    manager.setTheme(event.target.checked ? 'dark' : 'light');
  };

  return (
    <SwitcherWrapper
      label="Theme"
      checked={theme === 'dark'}
      onChange={handleThemeChange}
      valueLabel={theme === 'dark' ? 'Dark' : 'Light'} // Use theme name instead of On/Off
    />
  );
};

export default ThemeToggle;
