import { memo, useRef } from 'react';

import UniversalDialog, { UniversalDialogRef } from '@/components/MuiDialogs/UniversalDialog';
import { useAuthEvents } from '@/context/auth/useAuthEvents';
import { useTranslate } from '@/hooks/useTranslate';

import Login from '../Login';

const AuthDialog = () => {
    // hooks
    const authEvents = useAuthEvents();
    const { t } = useTranslate('auth_common');

    // states
    const dialogRef = useRef<UniversalDialogRef>(null);

    authEvents.openDialog.useListener(() => {
        dialogRef.current?.openDialog();
    });

    return (
        <div>
            <UniversalDialog
                ref={dialogRef}
                title={t('login.title')}
                showClose={true}
                loading={false}
                maxWidth="xs"
                transition="up"
                dividers={true}
                close={true}
                sx={{
                    p: 10,
                }}>
                <Login mode="modal" />
            </UniversalDialog>
        </div>
    );
}

export default memo(AuthDialog);