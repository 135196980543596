/**
 * i18n.js
 * 
 * Documentation:
 * https://github.com/aralroca/next-translate
 */

const axios = require('axios');
const config = require('./config');

// setting up namespaces
const commonNamespaces = ["common", "components", 'auth_common', 'layout'];


module.exports = {
    loader: false,
    locales: config.locales,
    defaultLocale: config.defaultLocale,
    pages: {
        "*": commonNamespaces,
        "rgx:^/auth(/.*)?$": ["auth"],
        "rgx:^/~/insider(/.*)?$": ["insider"],
        "rgx:^/~/map(/.*)?$": ["map"],
    },

    // namespaces for storybook
    namespaces: (lang) => {
        const allNamespaces = [...commonNamespaces];
        return allNamespaces.reduce((acc, ns) => {
            acc[ns] = require(`../public/locales/${lang}/${ns}.json`);
            return acc;
        }, {})
    },

    loadLocaleFrom: (lang, ns) => {
        // You can use a dynamic import, fetch, whatever. You should
        // return a Promise with the JSON file.
        return import(`../public/locales/${lang}/${ns}.json`).then((m) => m.default)
    },
    fallbackLng: config.defaultLocale,
    keySeparator: '.',
    interpolation: {
        formatSeparator: ',',
        escapeValue: false, // not needed for react as it escapes by default
        format: (value, format, lang) => {
            if (format === 'number') {
                return new Intl.NumberFormat(lang).format(value);
            }
            return value;
        },
    },
    defaultNS: 'common',
    logBuild: true,
    logger: async (msg) => {
        const { namespace, i18nKey } = msg;

        // console.log(`Translation key "${i18nKey}" not found in namespace "${namespace}"`);

        try {
            const url = `http://localhost:${process.env.PORT}/api/log-missing-translation/`
            await axios.post(url, { namespace, i18nKey });
        } catch (error) {
            // console.error('Error logging missing translation', error);
        }
    },
    loggerEnvironment: 'both'
}