
import api from '../base';
import type { Paths, Response, Schemas } from '../types';

// Context
export type MapContextResponse = Schemas['MapContext'];

// Finder
export type MapFinderPlacesFormRequest = Schemas['MapFinderPlacesFormRequest'];
export type MapFinderPlacesFormResponse = Schemas['MapFinderPlace'][];
export type PlaceInfo = Schemas['MapFinderPlace'];

// Events
export type MapFinderEventsFormRequest = Schemas['MapFinderEventsFormRequest'];
export type MapFinderEventsFormResponse = Schemas['MapFinderEvent'][];
export type EventInfo = Schemas['MapFinderEvent'];


class MapApi {

    static getContext(): Response<MapContextResponse> {
        const url: keyof Paths = '/dj/aimap/context/';
        return api.get({ url });
    }

    static findPlaces(data: MapFinderPlacesFormRequest): Response<MapFinderPlacesFormResponse> {
        const url: keyof Paths = '/dj/aimap/finder/places/';
        return api.post({
            url, data, config: {
                timeout: 1000 * 60 * 3, // 3 min
            }
        });
    }

    static findEvents(data: MapFinderEventsFormRequest): Response<MapFinderEventsFormResponse> {
        const url: keyof Paths = '/dj/aimap/finder/events/';
        return api.post({ url, data });
    }

}

export default MapApi;
