import { useTranslate } from '@/hooks/useTranslate';
import { Box, Typography } from '@mui/material';

import GoogleAuth from './GoogleAuth';

export default function SocialLogin() {
    const { t } = useTranslate('auth_common');

    return (
        <Box>
            <Typography variant="body2" align="center" color="textSecondary" mb={3}>
                {t('login.or_sign_in_with')}
            </Typography>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}>
                <GoogleAuth />
            </Box>
        </Box>
    )
}