import { useTranslate } from '@/hooks/useTranslate';
import CustomLayout from '@/layouts/CustomLayout';

import config from '../core/config';
import appRoutes from '../core/routes';
import ContextProvider from './context';

interface Props {
    children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
    const { t } = useTranslate('insider');

    return (
        <CustomLayout
            config={{
                app: {
                    name: config.app.name,
                    description: 'Discover the best AI startups',
                },
                menu: {
                    default: {
                        home: {
                            name: t('common.home'),
                            item: appRoutes.home,
                        },
                    },
                    left: [
                        {
                            name: t('common.my-insights'),
                            item: appRoutes.cabinet.requests.index,
                            icon: 'hugeicons:analytics-01',
                        },
                        {
                            name: t('common.idea-generator'),
                            item: appRoutes.idea.index,
                            icon: 'hugeicons:ai-idea',
                        },
                        {
                            name: t('common.catalog'),
                            item: appRoutes.catalog.index,
                            icon: 'hugeicons:ai-chat-02',
                        },
                        {
                            name: t('common.investors'),
                            item: appRoutes.investors.index,
                            icon: 'solar:money-bag-linear',
                        }
                    ],
                    right: [
                        {
                            name: t('common.about'),
                            item: appRoutes.about
                        },
                        {
                            name: t('common.contact'),
                            item: appRoutes.contact
                        }
                    ],
                },
            }}>
            {children}
        </CustomLayout>
    )
}

export default (props: Props) => (
    <ContextProvider>
        <Layout {...props} />
    </ContextProvider>
);