import { useState } from 'react';

import { useI18n } from './useI18n';

import type { AppProps, StateProps } from './types';


export const useApp = ({ appProps }: AppProps) => {
    const [state, setState] = useState<StateProps>({
        windowHeight: 0,
        appBarHeight: 0,
        appProps: appProps || {},
        isPwa: false,
        deviceType: appProps?.deviceType || 'desktop',
        theme: 'light',
    });

    useI18n();

    const dispatch = (action: Partial<StateProps>) => {
        setState((prev) => ({ ...prev, ...action }));
    };

    // Hydrate the state from the given pageProps
    const hydrate = ({ appProps }: AppProps) => {
        dispatch({
            appProps: appProps,
            deviceType: appProps.deviceType || 'desktop',
        });
    };

    // The manager object, containing various methods to manage state
    const manager = new class {
        get appProps(): AppProps {
            return state.appProps;
        }
        get pageUrl(): string {
            return state.appProps.router?.state?.route || '';
        }

        setAppBarHeight = (height: number) => {
            dispatch({ appBarHeight: height });
        };

        setTheme = (newTheme: string) => {
            dispatch({ theme: newTheme });
        };

        setDeviceType = (deviceType: string) => {
            dispatch({ deviceType });
        };

        // Update viewport dimensions
        updateViewport = (width: number, height: number) => {
            dispatch({ windowHeight: height });
            document.documentElement.style.setProperty("--vw", `${width}px`);
            document.documentElement.style.setProperty("--vh", `${height}px`);
        };

        // Initialize the app state
        autoInit = () => {
            if (appProps.deviceType) {
                this.setDeviceType(appProps.deviceType);
            }
        };
    };

    return {
        state,
        hydrate,
        manager,
    };
};
