import NextLink from 'next/link';
import { memo } from 'react';

import { Link } from '@/components/Link';
import config from '@/core/config';
import routes from '@/core/routes';
import useMedia from '@/hooks/useMedia';
import UserMenu from '@/layouts/CustomLayout/Appbar/snippets/UserMenu';
import { Box } from '@mui/material';

import { industries, menu } from './data';
import DrawerMenu from './DrawerMenu';

const AppbarDesktop = () => {

    // hooks
    const media = useMedia();

    return (
        <Box>
            <Box
                component="header"
                sx={{
                    py: 3,
                    px: 5,
                    height: theme => theme.mixins.appBarHeight,

                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                }}>
                    <Box component={NextLink} href={routes.home} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        textDecoration: 'none',
                        color: 'inherit',
                        mr: 5,
                    }}>
                        <Box component="img"
                            src={config.static.logo.svg}
                            alt={config.app.name} sx={{
                                width: 110,
                            }} />
                    </Box>

                    {media.up.lg && (
                        <Box sx={{
                            display: 'flex',
                            gap: 5,
                        }}>
                            {industries.map((item, index) => (
                                <Link key={index} href={item.href}
                                    variant='subtitle1'
                                    sx={{
                                        fontWeight: theme => theme.typography.fontWeightMedium,
                                        color: 'text.primary',
                                    }}>
                                    {item.name}
                                </Link>
                            ))}
                        </Box>
                    )}
                </Box>
                <Box flexGrow={1} />
                {media.up.md && (
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 5,
                        mr: 2,
                    }}>
                        {menu.map((item, index) => (
                            <Link key={index} href={item.href}
                                variant='subtitle1'
                                sx={{
                                    fontWeight: theme => theme.typography.fontWeightMedium,
                                    color: 'text.primary',
                                }}>
                                {item.name}
                            </Link>
                        ))}
                    </Box>
                )}

                <UserMenu />

                {media.down.lg && (
                    <Box mr={-2}>
                        <DrawerMenu />
                    </Box>
                )}

            </Box>
        </Box>
    )
}

export default memo(AppbarDesktop);