import '../theme/scss/globals.scss';

import { GeistSans } from 'geist/font/sans';
import appWithI18n from 'next-translate/appWithI18n';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';

import ContextProvider from '@/context';
import Analytics from '@/core/analytics';
import useEventTracker from '@/core/analytics/useEventTracker';
import LayoutBuilder from '@/layouts/Builder';
import ErrorBoundary from '@/layouts/ErrorBoundary';
import ThemeProvider from '@/theme/ThemeProvider/Default';
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import i18nSettings from '../../i18n';

import type { PageProps } from '@/types/pages';


interface MyAppProps extends AppProps {
  pageProps: PageProps;
}

const MyApp = (props: MyAppProps) => {

  const { Component, pageProps, router } = props;

  const eventTracker = useEventTracker();

  useEffect(() => {
    eventTracker.system.appLoaded();
  }, []);

  return (
    <AppCacheProvider {...props}>
      <Head>
        <meta
          name="viewport"
          content="user-scalable=no, initial-scale=1, minimum-scale=1, maximum-scale=1, width=device-width height=device-height, shrink-to-fit=no, viewport-fit=cover"
        />
      </Head>
      <main className={GeistSans.variable}>
        <ErrorBoundary>
          <ThemeProvider deviceType={pageProps.server?.deviceType}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Analytics />
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}>
                <ContextProvider appProps={{ ...pageProps, router }}>
                  <LayoutBuilder pageProps={pageProps}>
                    <Component {...pageProps} />
                  </LayoutBuilder>
                </ContextProvider>
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </ErrorBoundary>
      </main>
    </AppCacheProvider>
  );
};

// @ts-ignore
export default appWithI18n(MyApp, {
  ...i18nSettings,

  // Set to false if you want to load all the namespaces on _app.js getInitialProps
  skipInitialProps: false,
  loader: false,
});
