import log from 'loglevel';

import env from '@/core/env';

declare global {
    interface Window {
        logMessages: Array<{ level: string; message: any; args: any[]; timestamp: string }>;
    }
}

class LoggerWrapper {
    private logger: log.Logger;
    private static MAX_LOG_MESSAGES = 500;

    constructor() {
        this.logger = log.getLogger('default');

        if (typeof window !== 'undefined') {
            window.logMessages = [];
        }

        if (env.isDev) {
            this.logger.setLevel('debug');
            this.debug('Logger initialized in development mode');
        } else {
            this.logger.setLevel('info');
            this.info('Logger initialized in production mode');
        }
    }

    private logToWindow(level: string, message: any, args: any[]) {
        if (typeof window !== 'undefined') {
            const timestamp = new Date().toISOString();
            window.logMessages.push({ level, message, args, timestamp });
            if (window.logMessages.length > LoggerWrapper.MAX_LOG_MESSAGES) {
                window.logMessages.shift(); // Remove the oldest message
            }
        }
    }

    public info(message: any, ...args: any[]) {
        this.logger.info(message, ...args);
        this.logToWindow('info', message, args);
    }

    public warn(message: any, ...args: any[]) {
        this.logger.warn(message, ...args);
        this.logToWindow('warn', message, args);
    }

    public error(message: any, ...args: any[]) {
        this.logger.error(message, ...args);
        this.logToWindow('error', message, args);
    }

    public debug(message: any, ...args: any[]) {
        this.logger.debug(message, ...args);
        this.logToWindow('debug', message, args);
    }

    public setLevel(level: log.LogLevelDesc) {
        this.logger.setLevel(level);
    }
}

export const logger = new LoggerWrapper();
