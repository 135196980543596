
import { useEffect, useState } from 'react';

import TaskApi, { ContextBody } from '@/api/apps/ai-marketing-tool';

const initialState: ContextBody = {
    managers: [],
    targets: [],
    accounts: [],
    balance: 0,
    credit_cost: {},
    usd_to_points: 0,
};

export const useDataContext = () => {

    // states
    const [contextData, setContentData] = useState<ContextBody>(initialState);

    useEffect(() => {
        loadContext();
    }, []);

    const loadContext = async () => {
        await TaskApi.getContext().then((res) => {
            if (res.status === 200) {
                setContentData(res.data);
            }
        });
    };

    return {
        contextData,
    };
};