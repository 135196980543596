import { Autocomplete, Box, TextField } from '@mui/material';
import _ from 'lodash';
import React, { useCallback } from 'react';

import { useFormContext } from '../context';
import type { SelectAutocompleteFieldType, SelectOption } from '../types/fields.types';
import type { FormConfig } from '../types/form.types';

interface Props {
  config?: FormConfig;
  field: SelectAutocompleteFieldType;
  value: string | string[] | null;
  errorText?: string;
  onChange: (value: string | string[]) => void;
  onBlur: (event: React.FocusEvent<any>) => void;
}

const SelectAutocomplete = ({
  config,
  field,
  value,
  errorText,
  onChange,
  onBlur,
}: Props) => {
  const { isSubmitting } = useFormContext();
  const isDisabled = field.props?.disabled || isSubmitting;

  // Sort by group & label to mimic old code
  const options = _.sortBy(field.options, ['group', 'label']);

  // Find the "selected" single-value option
  let currentValue: SelectOption | null = null;
  if (typeof value === 'string') {
    currentValue = options.find((opt) => opt.value === value) || null;
  }

  const handleChange = useCallback(
    (_: any, selected: any) => {
      onChange(selected ? selected.value : null);
      document.activeElement?.blur();
    },
    [onChange]
  );

  return (
    <Autocomplete
      options={options}
      value={currentValue}
      onChange={handleChange}
      onBlur={onBlur}
      groupBy={(option) => field.groupBy ? field.groupBy(option) : option.group || ''}
      disabled={isDisabled}
      getOptionLabel={(opt) =>
        field.getOptionLabel ? field.getOptionLabel(opt) : opt.label
      }
      autoSelect
      disableCloseOnSelect={false}
      size={config?.size || 'small'}
      renderGroup={(params) => {
        return [
          <Box key={params.key} sx={{
            mt: 2,
            borderTop: 1,
            borderColor: 'divider',
            typography: 'subtitle2',
            color: 'text.secondary',
            px: 4,
            py: 2,
            fontWeight: theme => theme.typography.fontWeightMedium,
          }}>
            {params.group}
          </Box>,
          params.children,
        ];
      }}
      renderOption={(_, option) => {
        if (field.renderOption) {
          return (
            <Box onClick={() => handleChange(_, option)} sx={{
              cursor: 'pointer',
              px: 4,
              py: 2,
              transition: 'background-color 0.2s',
              '&:hover': {
                bgcolor: 'action.hover',
              },
              ...(value === option.value && {
                bgcolor: 'action.selected',
              }),
            }}>
              {field.renderOption(option)}
            </Box>
          );
        }
        return undefined;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={field.name}
          label={field.label}
          size="small"
          error={Boolean(errorText)}
          helperText={errorText}
        />
      )}
    />
  );
};

export default SelectAutocomplete;
