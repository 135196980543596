import React from 'react';
import { TextField as MuiTextField } from '@mui/material';
import type { TextFieldType } from '../types/fields.types';
import type { FormConfig } from '../types/form.types';
import { useFormContext } from '../context';

interface TextFieldRendererProps {
  config?: FormConfig;
  field: TextFieldType;
  value: any;
  errorText?: string;
  onChange: (value: any) => void;
  onBlur: (event: React.FocusEvent<any>) => void;
}

const TextFieldRenderer: React.FC<TextFieldRendererProps> = ({
  config,
  field,
  value,
  errorText,
  onChange,
  onBlur,
}) => {
  const { isSubmitting } = useFormContext();
  const isDisabled = field.props?.disabled || isSubmitting;

  return (
    <MuiTextField
      size={config?.size || 'small'}
      fullWidth
      name={field.name}
      label={field.label}
      type={field.type}
      value={value || ''}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      error={Boolean(errorText)}
      helperText={errorText}
      autoComplete="off"
      disabled={isDisabled}
      {...field.props}
    />
  );
};

export default TextFieldRenderer;
