const version = '1.0.2';

const makePath = (url: string) => {
    const baseUrl = 'https://static.unrealos.com';
    const app = 'unrealos';
    return `${baseUrl}/static/${app}${url}?v=${version}`;
};

const config = new class {
    app = {
        name: 'Unrealos',
    };

    // analytics = {
    // 	gaMeasurementId: 'G-TM6DHFCKF1',
    // 	mixpanelToken: '5d03e27a6ee8406909172ca011eb3bd9',
    // 	facebookPixelId: '',
    // };

    // theme = {
    // 	mode: 'dark',
    // };


    static = {
        // favicons: {
        // 	default: cacheBuster('/favicon.png'),
        // 	active: cacheBuster('/active.png'),
        // 	icon192: cacheBuster('/static/favicons/android-chrome-192x192.png'),
        // 	icon384: cacheBuster('/static/favicons/android-chrome-384x384.png'),
        // 	icon512: cacheBuster('/static/favicons/icon-512x512.png'),
        // },
        logo: {
            svg: makePath('/logo.svg'),
        },
        ogImage: makePath('/og-image.jpg'),
    };

    assets = {
        countryFlag: (code: string) => {
            if (code == 'en') code = 'gb';
            return {
                svg: `/static/country-flags/svg/${code}.svg`,
                png: `/static/country-flags/png/${code}.png`,
            };
        },
        logotypes: {
            epicGames: makePath('/logotypes/epic-games.svg'),
            lv80: makePath('/logotypes/lv80.svg'),
            coinTelegraph: makePath('/logotypes/coin-telegraph.svg'),
            forbes: makePath('/logotypes/forbes.svg'),
            vc: makePath('/logotypes/vc.svg'),
        },
        videos: {
            mainVideo: {
                desktop: {
                    url: makePath('/main-video/desktop.mp4'),
                    silent: makePath('/main-video/desktop-silent.mp4'),
                    preview: makePath('/main-video/preview-desktop.jpg'),
                },
                mobile: {
                    url: makePath('/main-video/mobile.mp4'),
                    silent: makePath('/main-video/mobile-silent.mp4'),
                    preview: makePath('/main-video/preview-mobile.jpg'),
                },
            },
        },
        images: {
            forbes: makePath('/images/forbes.jpg'),
        }
    };

    github = {
        getStatic: (path: string) => `https://raw.githubusercontent.com/markolofsen/unrealos4-refs/heads/main/${path}`,
        editContent: (path: string) => `https://github.com/markolofsen/unrealos4/edit/main/${path}`,
    };


    links = {
        forbesYoutube: 'https://www.youtube.com/watch?v=5bnQpaYiQXc&t=1389s',
    };



}

export default config;
