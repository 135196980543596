import { memo } from 'react';

import useMedia from '@/hooks/useMedia';

import AppBarDesktop from './Desktop';
import AppBarMobile from './Mobile';

const Appbar = () => {

    // hooks
    const media = useMedia();

    if (media.up.md) {
        return <AppBarDesktop />
    }

    return <AppBarMobile />
}

export default memo(Appbar);