import { useEffect } from 'react';
import * as Yup from 'yup';

import AutoForm, {
    FieldGenerator, FormField, FormikProvider, useFormContext
} from '@/components/Formik';
import { Link } from '@/components/Link';
import { useAuthContext } from '@/context';
import useEventTracker from '@/core/analytics/useEventTracker';
import routes from '@/core/routes';
import { useTranslate } from '@/hooks/useTranslate';
import { Box } from '@mui/material';

import AuthLayout from './snippets/AuthLayout';
import SocialLogin from './snippets/SocialLogin';

interface Props {
    mode?: 'modal';
}
const LoginForm = ({ mode }: Props) => {

    // contexts
    const { setSubmitting } = useFormContext();
    const { manager } = useAuthContext();
    const { t } = useTranslate('auth_common');

    // hooks
    const eventTracker = useEventTracker();

    // Track when the login form is rendered
    useEffect(() => {
        eventTracker.auth.authorization.start();
    }, []);

    // Define form fields
    const fields: FormField[] = [
        FieldGenerator.text({
            name: 'email',
            label: t('login.email'),
            validation: Yup.string()
                .email('Invalid email format')
                .required(t('login.email') + ' is required'),
            props: {
                placeholder: t('login.email_placeholder'),
                type: 'email',
                autoComplete: 'email',
            },
        }),
        FieldGenerator.password({
            name: 'password',
            label: t('login.password'),
            validation: Yup.string().required(t('login.password') + ' is required'),
            props: {
                placeholder: t('login.password_placeholder'),
            },
        }),
        // If you need 2FA:
        // FieldGenerator.text({
        //   name: 'two_factor_token',
        //   label: '2FA Token',
        //   validation: Yup.string(),
        //   props: { placeholder: 'Enter your 2FA token', type: 'number' },
        // }),
    ];

    // Handle form submission
    const handleSubmit = async (values: Record<string, any>) => {
        setSubmitting(true);

        try {
            const res = await manager.authenticate({
                email: values.email,
                password: values.password,
                two_factor_token: values.two_factor_token,
            });

            if (res.status === 200) {
                eventTracker.auth.authorization.success();
                manager.setDefaultEmail(values.email);
                manager.redirectIfLogged();
                window.common.notify({
                    message: t('login.notifications.success'),
                    variant: 'success',
                })
            } else if (res.status === 401) {
                window.common.notify({
                    message: t('login.notifications.invalid_credentials'),
                    variant: 'error',
                });
            } else if (res.status === 423) {
                window.common.notify({
                    message: t('login.notifications.2fa_required'),
                    variant: 'info',
                });
            } else {
                window.common.notify({
                    message: t('login.notifications.failed'),
                    variant: 'error',
                });
            }
        } catch (error) {
            window.common.notify({
                message: t('login.notifications.error'),
                variant: 'error',
            });
        }

        setSubmitting(false);
    };

    const renderLayout = (children: React.ReactNode) => {
        if (mode === 'modal') {
            return children;
        }

        return (
            <AuthLayout title={t('login.title')}>
                {children}
            </AuthLayout>
        );
    }

    return (
        <Box>
            {renderLayout(

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5,
                }}>
                    <AutoForm
                        config={{
                            size: 'medium',
                            recaptcha: true,
                        }}
                        fields={fields}
                        onSubmit={handleSubmit}
                        submitButton={{ children: t('login.submit') }}
                    />

                    <SocialLogin />

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                    }}>

                        <Link href={routes.auth.password.reset.path} variant="body2">
                            {t('login.forgot_password')}
                        </Link>

                        <Link href={routes.auth.register.path} variant="body2">
                            {t('login.no_account')}
                        </Link>

                    </Box>
                </Box>
            )}
        </Box>
    );
};


export default (props: Props) => (
    <FormikProvider>
        <LoginForm {...props} />
    </FormikProvider>
);
