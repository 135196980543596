

import type { LayoutConfig } from '../types';

interface Props {
    config: LayoutConfig;
}
export const useLayout = ({ config }: Props) => {

    const hydrate = (config: LayoutConfig) => {

        // console.log('config', config);

    };

    return {
        hydrate,
        config,
    };
};