import anime from 'animejs';
// libs
import { useEffect } from 'react'; // Hook to handle pupil following cursor across the entire window

interface Props {
    svgRef: React.RefObject<SVGSVGElement | null>;
    leftPupilRef: React.RefObject<SVGCircleElement | null>;
    rightPupilRef: React.RefObject<SVGCircleElement | null>;
    followCursor: boolean;
}
export const useFollowCursor = ({ svgRef, leftPupilRef, rightPupilRef, followCursor }: Props) => {
    useEffect(() => {
        const svgElement = svgRef?.current;

        const updatePupilPosition = (event: MouseEvent) => {
            if (!svgElement || !leftPupilRef?.current || !rightPupilRef?.current) return;

            const rect = svgElement.getBoundingClientRect();
            const mouseX = event.clientX;
            const mouseY = event.clientY;

            // Define centers of the eyes (approximate coordinates of the pupils' starting positions)
            const leftEyeCenter = { x: rect.left + 29.9, y: rect.top + 41.3 }; // Left eye center in window coordinates
            const rightEyeCenter = { x: rect.left + 61.2, y: rect.top + 41.3 }; // Right eye center in window coordinates
            const eyeRadius = 7; // Radius of the sclera
            const pupilPadding = 2; // Padding to prevent pupils from sticking to the edge

            // Helper function to move pupil within eye boundaries
            const calculatePupilPosition = (eyeCenter: { x: number; y: number }) => {
                const dx = mouseX - eyeCenter.x;
                const dy = mouseY - eyeCenter.y;
                const distance = Math.sqrt(dx * dx + dy * dy);

                // Clamp the pupil's movement within the sclera while applying the padding
                const maxDistance = eyeRadius - pupilPadding;
                const clampedDistance = Math.min(distance, maxDistance);

                // Calculate the new position for the pupil
                const angle = Math.atan2(dy, dx);
                const newX = eyeCenter.x + clampedDistance * Math.cos(angle);
                const newY = eyeCenter.y + clampedDistance * Math.sin(angle);

                return { x: newX - eyeCenter.x, y: newY - eyeCenter.y };
            };

            // Update left and right pupils
            const leftPupilPosition = calculatePupilPosition(leftEyeCenter);
            const rightPupilPosition = calculatePupilPosition(rightEyeCenter);

            anime.set(leftPupilRef.current, {
                translateX: leftPupilPosition.x,
                translateY: leftPupilPosition.y,
            });

            anime.set(rightPupilRef.current, {
                translateX: rightPupilPosition.x,
                translateY: rightPupilPosition.y,
            });
        };

        if (followCursor) {
            // Track cursor across the entire window
            window.addEventListener('mousemove', updatePupilPosition);
        }

        return () => {
            if (followCursor) {
                window.removeEventListener('mousemove', updatePupilPosition);
            }
        };
    }, [svgRef, leftPupilRef, rightPupilRef, followCursor]);
};
