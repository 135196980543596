import NextLink from 'next/link';
import { memo } from 'react';

import StickyBox from '@/components/StickyBox';
import routes from '@/core/routes';
import UserMenu from '@/layouts/CustomLayout/Appbar/snippets/UserMenu';
import { Box } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import { useLayoutContext } from '../context';
import DrawerMenu from './DrawerMenu';

const AppbarMobile = () => {

    // context
    const { config } = useLayoutContext();

    // hooks
    const theme = useTheme<Theme>();

    return (
        <StickyBox zIndex={theme.zIndex.appBar}>
            {({ isFixed }) => (
                <Box
                    component="header"
                    sx={{
                        py: 3,
                        pl: 5,
                        pr: 2,
                        height: theme => theme.mixins.appBarHeight,

                        position: 'relative',
                        zIndex: theme => theme.zIndex.appBar,

                        display: 'flex',
                        alignItems: 'center',
                        gap: 5,

                        borderBottom: 1,
                        borderColor: 'divider',
                        bgcolor: 'background.paper',

                        ...(isFixed && {
                            boxShadow: theme => theme.shadows[4],
                        }),
                    }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                    }}>
                        <DrawerMenu />
                        <Box component={NextLink} href={routes.home} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            textDecoration: 'none',
                            color: 'inherit',
                            typography: 'h6',
                        }}>
                            {config.app.name}
                            {/* <Box component="img"
                                src={config.static.logo.svg}
                                alt={config.app.name} sx={{
                                    width: 90,
                                }} /> */}
                        </Box>
                    </Box>
                    <Box flexGrow={1} />
                    <UserMenu />
                </Box>
            )}
        </StickyBox>
    )
}

export default memo(AppbarMobile);