
import api from '../base';

import type { Operations, Paths, Response, Schemas } from '../types';

// Context
export type ProductHuntContextResponse = Schemas['ProductHuntContext'];
export type ProductHuntCategory = Schemas['ProductHuntCategory'];

// Catalog
export type ProductHuntListParams = Operations['producthunt_products_list']['parameters']['query'];
export type ProductHuntListResponse = Schemas['PaginatedProductHuntListList'];
export type ProductHuntListItem = Schemas['ProductHuntList'];
export type ProductHuntDetailResponse = Schemas['ProductHuntDetail'];

// Autocomplete
export type ProductHuntAutocompleteParams = Operations['producthunt_search_autocomplete_retrieve']['parameters']['query'];
export type ProductHuntAutocompleteResponse = Schemas['ProductSearch'];
export type ProductAutocompleteAnswer = Schemas['ProductAutocompleteAnswer'];

// Evaluation
export type ProductHuntEvaluationRequest = Schemas['ProductEvaluationRequestRequest'];
export type ProductHuntEvaluationResponse = Schemas['ProductEvaluation'];
export type ProductHuntEvaluationAnswer = Schemas['ProductEvaluationAnswer'];

class ProductHuntApi {

    static getProductContext(): Response<ProductHuntContextResponse> {
        const url: keyof Paths = '/dj/producthunt/context/';
        return api.get({ url });
    }

    static getProductHuntList(params: ProductHuntListParams): Response<ProductHuntListResponse> {
        const url: keyof Paths = '/dj/producthunt/products/';
        return api.get({ url, params });
    }

    static getProductHuntDetail(slug: string): Response<ProductHuntDetailResponse> {
        const url: keyof Paths = '/dj/producthunt/detail/{slug}/';
        const url_slug = url.replace('{slug}', slug);
        return api.get({ url: url_slug });
    }

    static getAutocomplete(params: ProductHuntAutocompleteParams, signal: AbortSignal): Response<ProductHuntAutocompleteResponse> {
        const url: keyof Paths = '/dj/producthunt/search/autocomplete/';
        return api.get({ url, params, config: { signal } });
    }

    static evaluateProduct(data: ProductHuntEvaluationRequest): Response<ProductHuntEvaluationResponse> {
        const url: keyof Paths = '/dj/producthunt/evaluation/';
        return api.post({
            url, data, config: {
                timeout: 30000,
            }
        });
    }

}

export default ProductHuntApi;
