
import React from 'react';

// mui
import { Box, Switch, Typography } from '@mui/material';

interface SwitcherProps {
    label: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    valueLabel?: string; // Optional parameter to display theme name instead of On/Off
}

const SwitcherWrapper: React.FC<SwitcherProps> = ({
    label,
    checked,
    onChange,
    valueLabel, // Display custom label (e.g., theme) instead of On/Off
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
            }}
        >
            <Typography variant="subtitle2">
                {label}
                <Box component="span" sx={{ ml: 1, color: 'text.secondary' }}>
                    ({valueLabel ? valueLabel : checked ? 'On' : 'Off'})
                </Box>
            </Typography>

            <Switch
                checked={checked}
                onChange={onChange}
                color="default"
                size="small"
                sx={{
                    mr: -2,
                }}
            />
        </Box>
    );
};

export default SwitcherWrapper;
