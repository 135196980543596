import { Box, Slider as MuiSlider, Typography } from '@mui/material';
import React from 'react';
import { useFormContext } from '../context';
import type { SliderFieldType } from '../types/fields.types';
import type { FormConfig } from '../types/form.types';

interface SliderFieldRendererProps {
    config?: FormConfig;
    field: SliderFieldType;
    value: number;
    errorText?: string;
    onChange: (value: number) => void;
    onBlur: (event: React.FocusEvent<any>) => void;
}

const SliderFieldRenderer: React.FC<SliderFieldRendererProps> = ({
    config,
    field,
    value,
    errorText,
    onChange,
    onBlur,
}) => {
    const { isSubmitting } = useFormContext();
    const isDisabled = field.props?.disabled || isSubmitting;

    const valueLabelFormat = field.props?.valueLabelFormat || ((value: number) => `${value}`);
    const sliderValue = value || field.props?.min || 0;

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body2" color="text.secondary">
                    {field.label}
                </Typography>
                <Typography variant="caption" color="text.primary">
                    {valueLabelFormat(sliderValue)}
                </Typography>
            </Box>
            <MuiSlider
                {...field.props}
                size={config?.size || 'small'}
                value={sliderValue}
                onChange={(event, newValue) => {
                    onChange(newValue as number);
                }}
                onBlur={onBlur}
                disabled={isDisabled}
                min={field.props?.min || 0}
                max={field.props?.max || 100}
                step={field.props?.step || 1}
                sx={{
                    color: 'inherit',
                    '& .MuiSlider-markLabel': {
                        typography: 'caption',
                    },
                }}
            />
            {errorText && (
                <Typography color="error" variant="caption" sx={{ mt: 0.5 }}>
                    {errorText}
                </Typography>
            )}
        </Box>
    );
};

export default SliderFieldRenderer;
