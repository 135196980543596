import type {
  CheckboxFieldType,
  DateFieldType,
  FormFieldCustom,
  GoogleAddressFieldType,
  SelectAutocompleteFieldType,
  SelectFieldType,
  SelectMultipleFieldType,
  SliderFieldType,
  TextFieldType,
  TimeFieldType,
} from '../types/fields.types';

/**
 * Matches your old "FieldGenerator" approach,
 * but references the new field interfaces.
 */
export class FieldGenerator {
  static text(params: Omit<TextFieldType, 'type'>): TextFieldType {
    return { type: 'text', ...params };
  }
  static textArea(params: Omit<TextFieldType, 'type'>): TextFieldType {
    return {
      type: 'text',
      ...params,
      props: { multiline: true, rows: 4, ...params.props },
    };
  }
  static password(params: Omit<TextFieldType, 'type'>): TextFieldType {
    return { type: 'password', ...params };
  }
  static number(params: Omit<TextFieldType, 'type'>): TextFieldType {
    return { type: 'number', ...params };
  }
  static email(params: Omit<TextFieldType, 'type'>): TextFieldType {
    return { type: 'email', ...params };
  }
  static url(params: Omit<TextFieldType, 'type'>): TextFieldType {
    return { type: 'url', ...params };
  }
  static checkbox(params: Omit<CheckboxFieldType, 'type'>): CheckboxFieldType {
    return { type: 'checkbox', ...params };
  }
  static select(params: Omit<SelectFieldType, 'type'>): SelectFieldType {
    return { type: 'select', ...params };
  }
  static selectAutocomplete(
    params: Omit<SelectAutocompleteFieldType, 'type'>
  ): SelectAutocompleteFieldType {
    return { type: 'select-autocomplete', ...params };
  }
  static selectMultiple(
    params: Omit<SelectMultipleFieldType, 'type'>
  ): SelectMultipleFieldType {
    return { type: 'select-multiple', ...params };
  }
  static date(params: Omit<DateFieldType, 'type'>): DateFieldType {
    return { type: 'date', ...params };
  }
  static time(params: Omit<TimeFieldType, 'type'>): TimeFieldType {
    return { type: 'time', ...params };
  }
  static custom(params: Omit<FormFieldCustom, 'type'>): FormFieldCustom {
    return { type: 'custom', ...params };
  }
  static slider(params: Omit<SliderFieldType, 'type'>): SliderFieldType {
    return { type: 'slider', ...params };
  }
  static googleAddress(
    params: Omit<GoogleAddressFieldType, 'type'>
  ): GoogleAddressFieldType {
    return { type: 'google-address', ...params };
  }
}
