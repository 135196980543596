import React from 'react';
import { useFormContext } from '../context';

import CheckboxRenderer from './Checkbox';
import CustomComponentRenderer from './CustomComponent';
import DateTimeFieldRenderer from './DateTimeField';
import GoogleAddressFieldRenderer from './GoogleAddressField';
import SelectAutocomplete from './SelectAutocomplete';
import SelectField from './SelectField';
import SelectMultipleField from './SelectMultipleField';
import SliderFieldRenderer from './Slider';
import TextFieldRenderer from './TextField';

import type { FormField } from '../types/fields.types';
import type { FormConfig } from '../types/form.types';

interface FieldProps {
  field: FormField;
  config?: FormConfig;
  value: any;
  errorText?: string;
  onChange: (value: any) => void;
  onBlur: (event: React.FocusEvent<any>) => void;
}

/**
 * A single FieldRenderer that checks "field.type"
 * and renders the correct component.
 */
function FieldRenderer(props: FieldProps) {
  const { field } = props;

  switch (field.type) {
    case 'checkbox':
      return <CheckboxRenderer {...props} field={field} />;
    case 'date':
    case 'time':
      return <DateTimeFieldRenderer {...props} field={field} />;
    case 'select':
      return <SelectField {...props} field={field} />;
    case 'select-autocomplete':
      return <SelectAutocomplete {...props} field={field} />;
    case 'select-multiple':
      return <SelectMultipleField {...props} field={field} />;
    case 'text':
    case 'password':
    case 'number':
    case 'email':
    case 'url':
      return <TextFieldRenderer {...props} field={field} />;
    case 'custom':
      return <CustomComponentRenderer {...props} field={field} />;
    case 'google-address':
      return <GoogleAddressFieldRenderer {...props} field={field} />;
    case 'slider':
      return <SliderFieldRenderer {...props} field={field} />;
    default:
      return null;
  }
}

interface FieldsProps {
  field: FormField;
  config?: FormConfig;
  value: any;
  errorText?: string;
  onChange: (val: any) => void;
  onBlur: (event: React.FocusEvent<any>) => void;
}

function Fields(props: FieldsProps) {
  const { errors } = useFormContext();

  const fieldError = errors[props.field.name] as string | undefined;

  // We could do more logic here if needed
  return (
    <FieldRenderer {...props} errorText={fieldError} />
  );
}

/**
 * A default export that receives "field & config & value & errorText" 
 * and chooses the correct specialized renderer.
 */
export default Fields;
