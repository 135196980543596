import { nanoid } from 'nanoid';

const cacheUuid = nanoid(10);

const makePath = (url: string) => {
    const baseUrl = 'https://static.unrealos.com';
    const app = 'ai';
    return `${baseUrl}/static/${app}${url}?cache=${cacheUuid}`;
};

const config = new class {
    app = {
        name: 'AI Insider',
    };

    // analytics = {
    // 	gaMeasurementId: 'G-TM6DHFCKF1',
    // 	mixpanelToken: '5d03e27a6ee8406909172ca011eb3bd9',
    // 	facebookPixelId: '',
    // };

    // theme = {
    // 	mode: 'dark',
    // };


    static = {
        // favicons: {
        // 	default: cacheBuster('/favicon.png'),
        // 	active: cacheBuster('/active.png'),
        // 	icon192: cacheBuster('/static/favicons/android-chrome-192x192.png'),
        // 	icon384: cacheBuster('/static/favicons/android-chrome-384x384.png'),
        // 	icon512: cacheBuster('/static/favicons/icon-512x512.png'),
        // },
        logo: {
            svg: makePath('/logo.svg'),
        },
        ogImage: makePath('/og-image.jpg'),
    };

    assets = {

    };
}

export default config;
