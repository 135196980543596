import NextLink from 'next/link';
import { useEffect } from 'react';

import useEventTracker from '@/core/analytics/useEventTracker';
import routes from '@/core/routes';
import useMedia from '@/hooks/useMedia';
import AnimatedBackground from '@/snippets/AnimatedBackground';
import LogoAnimation from '@/snippets/LogoAnimation';
import { Box, Container, Paper, Typography } from '@mui/material';

interface AuthFormProps {
    children: React.ReactNode;
    title: string;
}

const AuthLayout = ({ children, title }: AuthFormProps) => {
    const eventTracker = useEventTracker();
    const media = useMedia();

    useEffect(() => {
        // Track the start of an authorization page visit
        eventTracker.pages.visitAuth(title);
    }, [title]);

    const commonContent = (
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 5,
            }}>
                <NextLink href={routes.home}>
                    <LogoAnimation
                        size={media.down.sm ? 80 : 100}
                        followCursor
                    />
                </NextLink>
            </Box>
            <Typography variant="h4" color="textSecondary" sx={{
                textAlign: 'center',
                mb: 10,
                cursor: 'default',
            }}>
                {title}
            </Typography>
            {children}
        </>
    );

    if (media.down.sm) {
        return (
            <Box sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                p: 10,
            }}>
                {commonContent}
            </Box>
        );
    }

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
        }}>
            <AnimatedBackground sx={{
                opacity: .2,
            }} />
            <Container maxWidth="sm">
                <Box sx={{
                    p: 10,
                }}>
                    <Paper elevation={3} sx={{
                        p: 10,
                        bgcolor: 'background.paper',
                        zIndex: 1,
                        position: 'relative',
                    }}>
                        {commonContent}
                    </Paper>
                </Box>
            </Container>
        </Box>
    );
}

export default AuthLayout;
