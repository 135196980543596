
import api from '../base';

import type { CustomNode } from '@/apps/ai-marketing-tool/types';
import type { Response } from '../types';


// Extendable body schema for Task API
export type TaskBody = CustomNode[];

export type TargetItem = {
    key: string;
    name: string;
    icon: string;
    type: 'social' | 'messenger' | 'site';
    url: string;
    items: {
        label: string;
        value: string;
        action: 'url' | null;
    }[];
}
export type AccountItem = {
    uuid: string;
    username: string;
    target: string;
}

export interface ContextBody {
    managers: {
        uuid: string;
        name: string;
    }[];
    targets: TargetItem[];
    accounts: AccountItem[];
    balance: number;
    credit_cost: {
        [key: string]: number;
    };
    usd_to_points: number;
}


class AiMarketingToolApi {

    static getContext(): Response<ContextBody> {
        const url = '/dj/tasks/context/';
        return api.get({ url, mocked: true });
    }

    static getTask(uuid: string): Response<TaskBody> {
        const url = `/dj/tasks/${uuid}/`;
        return api.get({ url, mocked: true });
    }

}

export default AiMarketingToolApi;
