
import ThemeToggle from './ThemeToggle';

const Switchers = () => {

    return (
        <>

            <ThemeToggle />

            {/* <SwitcherWrapper
                label="Tooltips"
                checked={settings.showTooltips}
                onChange={() => {
                    settings.switchTooltips();
                }}
            />

            <SwitcherWrapper
                label="Tips"
                checked={settings.showTips}
                onChange={() => {
                    settings.switchTips();
                }}
            /> */}


        </>
    )
}

export default Switchers;