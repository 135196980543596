import type { UserData } from '@/api/methods/auth';
import { useEvents } from '@/hooks/useEvents';

export const useAuthEvents = () => {
    const openDialog = useEvents<void>('authOpenDialog');

    const userAuth = useEvents<{ isLogged: boolean; userData: UserData | null }>('userAuth');

    return {
        openDialog,
        userAuth,
    };
};
