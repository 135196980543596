import { useRouter } from 'next/router';
import { memo } from 'react';

import { Link } from '@/components/Link';
import config from '@/core/config';
import routes from '@/core/routes';
import { useMedia } from '@/hooks/useMedia';
import { useTranslate } from '@/hooks/useTranslate';
import { Box, Typography } from '@mui/material';

const Footer = () => {

    // hooks
    const media = useMedia();
    const router = useRouter();
    const { t, locales, changeLanguage } = useTranslate();

    // locales list except russian
    const localesList = locales.filter(locale => locale.code !== 'ru');

    return (
        <Box
            component="footer"
            sx={{

                px: 5,
                bgcolor: 'background.default',
                borderTop: 1,
                borderColor: 'divider',

                ...(media.up.sm ? {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    py: 3,
                } : {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 5,
                    py: 10,
                }),
            }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
            }}>
                <Link href={routes.home} variant="h6" color="text.secondary">
                    {config.app.name}
                </Link>
                <Typography variant="subtitle2" color="text.secondary">
                    — AI Startup Studio
                </Typography>
            </Box>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
            }}>
                {localesList.map((locale, index) => {
                    const flag = config.assets.countryFlag(locale.code).svg;
                    const selected = locale.code === router.locale;
                    return (
                        <Box key={index}
                            // variant="subtitle2"
                            // color="text.primary"
                            // href={router.asPath} locale={locale.code}
                            onClick={() => changeLanguage(locale.code)}
                            sx={{
                                typography: 'subtitle2',
                                color: 'text.primary',
                                cursor: 'pointer',

                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                border: 1,
                                borderColor: 'transparent',
                                borderRadius: 1,
                                px: 3,
                                py: 1,
                                ...(selected && {
                                    color: 'text.secondary',
                                    borderColor: 'divider',
                                }),
                            }}>
                            <Box component="img" src={flag} alt={locale.name} sx={{ width: 20, height: 20, mr: 1 }} />
                            {locale.name}
                        </Box>
                    )
                })}
            </Box>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                '& [data-divider]': {
                    color: 'divider',
                },
            }}>
                <Link href={routes.docs.privacy.path} variant="subtitle2" color="text.secondary">
                    Privacy Policy
                </Link>
                <Box data-divider>|</Box>
                <Link href={routes.docs.terms.path} variant="subtitle2" color="text.secondary">
                    Terms of Service
                </Link>
            </Box>
        </Box>
    )
}

export default memo(Footer);