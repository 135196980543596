import { ReactNode } from 'react';

import { createContextBuilder } from '@/utils/context';

import { useForm } from './useForm';

/**
 * We create a "FormikProvider" that actually uses our custom "useForm" hook,
 * so old imports like "import FormikProvider from '...' " will still work.
 */

const { Provider: FormProvider, useContextHook: useFormContext } = createContextBuilder(useForm);

export { useFormContext };

/**
 * We also wrap everything in ReCaptchaProvider for the recaptcha logic, 
 * just like your old code. Feel free to remove or adjust if not needed.
 */
interface Props {
  children: ReactNode;
}

export default function FormikProvider({ children }: Props) {
  return (
    <FormProvider>
      {children}
    </FormProvider>
  );
}
