import axios, {
    AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig
} from 'axios';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import https from 'https';

import env from '@/core/env';
import { logger } from '@/utils/logger';

import AuthApi from './methods/auth';

class Api {
    private instance: AxiosInstance;

    constructor() {
        this.instance = axios.create({
            // baseURL: undefined,

            // 30 seconds
            timeout: 1000 * 30,

            // Disable certificate verification
            httpsAgent: new https.Agent({
                rejectUnauthorized: false,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // --------------------------
        // REQUEST INTERCEPTOR
        // --------------------------
        this.instance.interceptors.request.use(
            (config: InternalAxiosRequestConfig) => {
                // Add request start time
                config.headers['x-request-start'] = new Date().toISOString();

                // Check if we're in a browser environment
                const isBrowser = typeof window !== 'undefined';
                const isServer = !isBrowser;

                // Get cookies only if in the browser
                const accessToken = isBrowser ? getCookie('access_token') : null;
                const userLang = isBrowser ? (getCookie('user_lang') || 'en') : 'en';

                // Resolve the timezone only if in the browser
                let timezone = 'UTC';
                if (isBrowser) {
                    try {
                        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
                    } catch (error) {
                        // Optionally log the error
                    }
                }

                // Set Authorization header if we have an access token
                if (accessToken) {
                    config.headers['Authorization'] = `Bearer ${accessToken}`;
                }

                // Language, timezone and IP headers
                config.headers['Accept-Language'] = userLang;
                config.headers['X-Timezone'] = timezone;
                // Remove the X-Forwarded-For header since we can't reliably get the client IP from the browser
                // The actual client IP will be determined by the server

                let url = config.url || '';
                // const isNextApi = url.startsWith('/api/');

                // If mocked, set the base URL to the mock server
                if (config.headers?.['x-mocked'] === 'true') {
                    url = `/api/${url.replace(/^\/+/, '')}`;
                }

                // Set the final URL
                if (isServer) {
                    config.url = `${env.FRONTEND_URL}${url}`;
                } else {
                    // For native CORS
                    config.url = `${window.location.origin}${url}`;
                }

                return config;
            },
            (error) => Promise.reject(error)
        );

        // --------------------------
        // RESPONSE INTERCEPTOR
        // --------------------------
        this.instance.interceptors.response.use(
            (response: AxiosResponse) => {
                this._logRequest(response.config, response.status);
                return response;
            },
            async (error) => {
                this._logRequest(error.config, error.response?.status, error.message);

                const originalRequest = error.config;

                // Handle 401 errors and attempt token refresh
                if (error.response?.status === 401 && originalRequest && !originalRequest._retry) {
                    originalRequest._retry = true;

                    const refreshToken = getCookie('refresh_token');
                    if (typeof refreshToken === 'string') {
                        try {
                            const { data } = await AuthApi.tokenRefresh({ refresh: refreshToken });

                            // Update the access token and retry the request
                            setCookie('access_token', data.access);
                            originalRequest.headers['Authorization'] = `Bearer ${data.access}`;
                            const retryResponse = await this.instance(originalRequest);

                            return {
                                success: true,
                                data: retryResponse.data,
                                status: retryResponse.status,
                            };
                        } catch {
                            deleteCookie('access_token');
                            deleteCookie('refresh_token');
                        }
                    }
                }

                // Return a consistent error response
                return {
                    error: error.message,
                    status: error.response?.status || null,
                    data: error.response?.data || null,
                };
            }
        );

    }

    /**
     * Private method to generate custom headers if needed.
     * e.g. for mocking or any additional headers.
     */
    private _updateInstanceConfig(mocked: boolean | null, config: AxiosRequestConfig) {
        const isMockedDefault = env.MOCK_API ? 'true' : 'false';
        const isMocked = mocked === null ? isMockedDefault : mocked;

        if (config.timeout) {
            this.instance.defaults.timeout = config.timeout;
        }

        Object.assign(this.instance.defaults.headers, {
            ...config.headers,
            'x-mocked': isMocked.toString(),
        });
    }

    private _logRequest(config: any, status: number | undefined, error?: string) {
        if (env.isProd) return

        const startTime = config?.headers?.['x-request-start'];
        if (startTime) {
            const duration = new Date().getTime() - new Date(startTime).getTime();
            logger.debug('API Request', {
                method: config?.method?.toUpperCase(),
                url: config?.url,
                status,
                ...(error && { error }),
                duration: `${duration}ms`,
            });
        }
    }

    // -----------------------------------
    // GET
    // -----------------------------------
    async get<T = any>({
        url,
        mocked = null,
        params = {},
        config = {},
    }: {
        url: string;
        mocked?: boolean | null;
        params?: Record<string, any>;
        config?: AxiosRequestConfig;
    }): Promise<AxiosResponse<T>> {
        this._updateInstanceConfig(mocked, config);
        return this.instance.get<T>(url, { ...config, params });
    }

    // -----------------------------------
    // POST
    // -----------------------------------
    async post<T = any>({
        url,
        data,
        mocked = null,
        params = {},
        config = {},
    }: {
        url: string;
        data?: any;
        mocked?: boolean | null;
        params?: Record<string, any>;
        config?: AxiosRequestConfig;
    }): Promise<AxiosResponse<T>> {
        this._updateInstanceConfig(mocked, config);
        return this.instance.post<T>(url, data, {
            ...config,
            params
        });
    }

    // -----------------------------------
    // PUT
    // -----------------------------------
    async put<T = any>({
        url,
        data,
        mocked = null,
        params = {},
        config = {},
    }: {
        url: string;
        data?: any;
        mocked?: boolean | null;
        params?: Record<string, any>;
        config?: AxiosRequestConfig;
    }): Promise<AxiosResponse<T>> {
        this._updateInstanceConfig(mocked, config);
        return this.instance.put<T>(url, data, { ...config, params });
    }

    // -----------------------------------
    // DELETE
    // -----------------------------------
    async delete<T = any>({
        url,
        mocked = null,
        params = {},
        config = {},
    }: {
        url: string;
        mocked?: boolean | null;
        params?: Record<string, any>;
        config?: AxiosRequestConfig;
    }): Promise<AxiosResponse<T>> {
        this._updateInstanceConfig(mocked, config);
        return this.instance.delete<T>(url, { ...config, params });
    }
}

// Export a singleton instance
const api = new Api();
export default api;
