import { useRef, useState } from 'react';

/**
 * Our custom "useForm" hook, returning similar state
 * to what your old code expected, but no real Formik inside.
 */
export const useForm = () => {
  // You used formRef for direct access to "FormikProps",
  // but we'll keep a ref for future expansions or advanced usage.
  const formRef = useRef<any>(null);

  // States
  const [values, setValues] = useState<Record<string, any>>({});
  const [errors, _setErrors] = useState<Record<string, any>>({});
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [storeValues, setStoreValues] = useState<Record<string, any>>({});
  const [currentValues, setCurrentValues] = useState<Record<string, any>>({});
  const [currentErrors, setCurrentErrors] = useState<Record<string, any>>({});

  const setErrors = (newErrors: Record<string, any>) => {
    // Convert array-based errors to string-based
    const convertedErrors = Object.entries(newErrors).reduce<Record<string, string>>((acc, [key, value]) => {

      // either object or array
      if (Array.isArray(value)) {
        acc[key] = value.join(', ');
      }

      else if (typeof value === 'string') {
        acc[key] = value.substring(0, 100)
      }

      return acc;
    }, {});
    _setErrors(convertedErrors);
  }

  // Reset the entire form
  const resetForm = () => {
    setSubmitting(false);
    setValues({});
    setErrors({});
    resetStoreValues();
  };

  // Update store values (the old "updateStoreValues")
  const updateStoreValues = (name: string, value: any) => {
    setStoreValues((prev) => ({ ...prev, [name]: value }));
  };

  const updateCurrentValues = (name: string, value: any) => {
    setCurrentValues((prev) => ({ ...prev, [name]: value }));
  };

  const resetStoreValues = () => {
    setStoreValues({});
  };

  const getKey = (key: string) => {
    return `form-${key}`;
  }

  // Add new method to handle local storage
  const saveToLocalStorage = (key: string, data: Record<string, any>) => {
    const storageKey = getKey(key);
    try {
      localStorage.setItem(storageKey, JSON.stringify(data));
    } catch (error) {
      console.error('Error saving form data to localStorage:', error);
    }
  };

  const loadFromLocalStorage = (key: string): Record<string, any> => {
    try {
      const storageKey = getKey(key);
      const stored = localStorage.getItem(storageKey);
      return stored ? JSON.parse(stored) : {};
    } catch (error) {
      console.error('Error loading form data from localStorage:', error);
      return {};
    }
  };

  const clearLocalStorage = (key: string) => {
    try {
      const storageKey = getKey(key);
      localStorage.removeItem(storageKey);
    } catch (error) {
      console.error('Error clearing form data from localStorage:', error);
    }
  };

  return {
    // Public references
    formRef,

    values,
    setValues,

    errors,
    setErrors,

    currentValues,
    setCurrentValues,

    currentErrors,
    setCurrentErrors,
    updateCurrentValues,

    isSubmitting,
    setSubmitting,

    storeValues,
    updateStoreValues,
    resetStoreValues,

    resetForm,

    saveToLocalStorage,
    loadFromLocalStorage,
    clearLocalStorage,
  };
};
