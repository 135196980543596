import NextLink from 'next/link';
import { useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import {
    Box, Divider, Drawer, IconButton, List, ListItemButton, ListItemText
} from '@mui/material';

import { industries, menu } from './data';

const DrawerMenu = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>) => {
        if ('key' in event && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const DrawerContent = (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List sx={{
                '& .MuiTypography-root': {
                    typography: 'subtitle1',
                },
            }}>
                {industries.map((item, index) => (
                    <ListItemButton key={index} LinkComponent={NextLink} href={item.href}>
                        <ListItemText primary={item.name} />
                    </ListItemButton>
                ))}
                <Divider />
                {menu.map((item, index) => (
                    <ListItemButton key={index} LinkComponent={NextLink} href={item.href}>
                        <ListItemText primary={item.name} />
                    </ListItemButton>
                ))}
            </List>
        </Box>
    );

    return (
        <>
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
            >
                <MenuIcon />
            </IconButton>

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                {DrawerContent}
            </Drawer>
        </>
    );
};

export default DrawerMenu;