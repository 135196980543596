class Routes {
	home = this.route('/~/os')
	about = this.route('/~/os/about')
	contact = this.route('/~/os/contact')

	services = {
		ai: this.route('/~/os/services/ai'),
		automotive: this.route('/~/os/services/automotive'),
		unrealEngine: this.route('/~/os/services/unreal-engine'),
		realEstate: this.route('/~/os/services/real-estate'),
		cloud: this.route('/~/os/services/cloud'),
	}

	solutions = {
		index: this.route('/~/os/solutions'),
		detail: this.route('/~/os/solutions/[slug]', ['slug']),
	}

	projects = {
		index: this.route('/~/os/projects'),
		detail: this.route('/~/os/projects/[slug]', ['slug']),
	}

	// Sample with parameters
	// projects = {
	// 	index: this.route('/projects'),
	// 	detail: this.route('/projects/[slug]', ['slug']),
	// };

	/**
	 * Helper method to define a route with or without parameters.
	 *
	 * @param path - The base path of the route.
	 * @param params - Optional placeholders for dynamic parts of the route.
	 * @returns Object containing `as` (for dynamic URLs) and `path`.
	 */
	route(path: string, params?: string[]) {
		if (params) {
			const paramPath = params.reduce((acc, param) => acc.replace(`[${param}]`, `:${param}`), path);
			return {
				as: (values: Record<string, string>) =>
					params.reduce((acc, param) => acc.replace(`:${param}`, values[param]), paramPath),
				path: paramPath,
			};
		}
		return { as: () => path, path };
	}
}

const routes = new Routes();


export default routes;
