
import { ErrorInfo } from 'react';

import { Link } from '@/components/Link';
import routes from '@/core/routes';
import { useCopy } from '@/hooks/useCopy';
import LogoAnimation from '@/snippets/LogoAnimation';
import ThemeDefault from '@/theme/ThemeProvider/Default';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, Container, TextField, Tooltip, Typography } from '@mui/material';

interface Props {
    error: Error | null;
    errorInfo: ErrorInfo | null;
    onReset: () => void;
}

const ErrorFallback: React.FC<Props> = ({ error, errorInfo, onReset }) => {
    // hooks
    const copy = useCopy();

    const handleCopyError = () => {
        copy.copy(JSON.stringify({ error, errorInfo }, null, 2));
    };

    return (
        <Box
            sx={{
                height: '100vh',
                py: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Container maxWidth="md">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 10,
                        textAlign: 'center',
                    }}
                >
                    <LogoAnimation size={100} followCursor />
                    <Box>
                        <Typography variant="h2" align="center" gutterBottom>
                            Something went wrong
                        </Typography>
                        <Typography variant="body1" align="center">
                            Please try again or contact support.
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button component="a" href="/" variant="contained" color="primary">
                            Go to Home
                        </Button>
                        <Button
                            startIcon={<RefreshIcon />}
                            variant="outlined"
                            color="inherit"
                            onClick={() => window.location.reload()}
                        >
                            Reload
                        </Button>
                    </Box>

                    <Box>
                        <Link href={routes.pages.contact.path} align="center" variant="body2" target="_blank">
                            Contact Support
                        </Link>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            position: 'relative',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                p: 5,
                                zIndex: 1,
                            }}
                        >
                            <Tooltip title={copy.copied ? 'Error data copied!' : 'Copy error details'}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disabled={copy.copied}
                                    onClick={handleCopyError}
                                >
                                    {copy.copied ? 'Copied' : 'Copy'}
                                </Button>
                            </Tooltip>
                        </Box>

                        <TextField
                            value={JSON.stringify({ error, errorInfo }, null, 2)}
                            fullWidth
                            multiline
                            rows={5}
                            inputProps={{
                                readOnly: true,
                            }}
                            sx={{
                                '.MuiInputBase-root': {
                                    bgcolor: 'background.paper',
                                },
                                '& .MuiInputBase-input': {
                                    fontFamily: 'monospace',
                                    whiteSpace: 'pre',
                                    fontSize: '0.75rem',
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

const ErrorBoundary = (props: Props) => (
    <ThemeDefault>
        <ErrorFallback {...props} />
    </ThemeDefault>
);

export default ErrorBoundary;