import { ErrorInfo, ReactNode, useEffect, useState } from 'react';

import useEventTracker from '@/core/analytics/useEventTracker';
import { logger } from '@/utils/logger';
import * as Sentry from '@sentry/nextjs';

// snippets
import ErrorFallback from './ErrorFallback';

interface Props {
    children: ReactNode;
    logError?: (error: Error, errorInfo: ErrorInfo) => void; // Optional custom error logging function
}

const ErrorBoundary = ({ children, logError }: Props) => {
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [errorInfo, setErrorInfo] = useState<ErrorInfo | null>(null);

    const eventTracker = useEventTracker();

    useEffect(() => {
        // Global error handling
        const handleGlobalError = (message: Event | string, source?: string, lineno?: number, colno?: number, err?: Error) => {
            if (err) {
                logger.error('Global Error Caught:', { message, source, lineno, colno, error: err });
                setHasError(true);
                setError(err);
                setErrorInfo({ componentStack: `Global error at ${source}:${lineno}:${colno}` } as ErrorInfo);

                // Track the app crash event with error details
                eventTracker.system.appCrashed({
                    error: err.message,
                    stack: err.stack,
                    source,
                    line: lineno,
                    column: colno,
                });
            }
        };

        const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
            logger.error('Unhandled Promise Rejection:', event.reason);
            Sentry.captureException(event.reason);
            setHasError(true);
            setError(event.reason);
            setErrorInfo({ componentStack: 'Unhandled Promise Rejection' } as ErrorInfo);

            // Track the app crash event with error details for promise rejections
            eventTracker.system.appCrashed({
                error: event.reason instanceof Error ? event.reason.message : String(event.reason),
                stack: event.reason instanceof Error ? event.reason.stack : undefined,
            });
        };

        window.onerror = handleGlobalError;
        window.onunhandledrejection = handleUnhandledRejection;

        return () => {
            // Clean up global handlers
            window.onerror = null;
            window.onunhandledrejection = null;
        };
    }, []);

    const handleReset = () => {
        setHasError(false);
        setError(null);
        setErrorInfo(null);
        window.location.reload(); // Option to reload the page on reset
    };

    if (hasError) {
        return (
            <ErrorFallback
                error={error}
                errorInfo={errorInfo}
                onReset={handleReset} // Add reset functionality
            />
        );
    }

    return <>{children}</>;
};

export default ErrorBoundary;
