import AlertDialog from '@/components/MuiDialogs/AlertDialog';
import ConfirmDialog from '@/components/MuiDialogs/ConfirmDialog';
import PromptDialog from '@/components/MuiDialogs/PromptDialog';
import Snackbar from '@/components/MuiDialogs/Snackbar';
import PageProgress from '@/components/PageProgress';
import AuthDialog from '@/views/authenticate/snippets/AuthDialog';
import GoogleTapLogin from '@/views/authenticate/snippets/GoogleTapLogin';

import SeoData from './SeoData';

const CommonLayout = () => {
    return (
        <>
            <PageProgress />

            <AuthDialog />
            <GoogleTapLogin />

            <PromptDialog />
            <AlertDialog />

            <ConfirmDialog />
            <Snackbar />
            <SeoData />
        </>
    );
};


export default CommonLayout;