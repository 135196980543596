import React from 'react';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';

import { useFormContext } from '../context';
import type { SelectMultipleFieldType } from '../types/fields.types';
import type { FormConfig } from '../types/form.types';

interface Props {
  config?: FormConfig;
  field: SelectMultipleFieldType;
  value: string[] | null;
  errorText?: string;
  onChange: (value: string[]) => void;
  onBlur: (event: React.FocusEvent<any>) => void;
}

const SelectMultipleField = ({
  config,
  field,
  value,
  errorText,
  onChange,
  onBlur,
}: Props) => {
  const { isSubmitting } = useFormContext();
  const isDisabled = field.props?.disabled || isSubmitting;
  const selectedValues = value || [];

  const handleChange = (e: any) => {
    onChange(e.target.value as string[]);
  };

  return (
    <FormControl
      fullWidth
      size={config?.size || 'small'}
      error={Boolean(errorText)}
      disabled={isDisabled}
      variant="outlined"
    >
      <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
      <Select
        labelId={`${field.name}-label`}
        multiple
        value={selectedValues}
        onChange={handleChange}
        onBlur={onBlur}
        input={<OutlinedInput label={field.label} />}
        renderValue={(selected: string[]) =>
          selected
            .map(
              (val) => field.options.find((opt) => opt.value === val)?.label || val
            )
            .join(', ')
        }
      >
        {field.options.map((option) => {
          const isChecked = selectedValues.includes(option.value);
          return (
            <MenuItem key={option.value} value={option.value} dense>
              <Checkbox checked={isChecked} />
              <ListItemText
                primary={
                  <Typography variant="body2">
                    {option.label}
                  </Typography>
                }
              />
            </MenuItem>
          );
        })}
      </Select>
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectMultipleField;
