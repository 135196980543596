import React from 'react';
import moment, { Moment } from 'moment';

import { Box, TextField as MuiTextField } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { useFormContext } from '../context';

import type { DateFieldType, TimeFieldType } from '../types/fields.types';
import type { FormConfig } from '../types/form.types';

interface DateTimeFieldRendererProps {
  config?: FormConfig;
  field: DateFieldType | TimeFieldType;
  value: string | Date | null;
  errorText?: string;
  onChange: (value: Date | null) => void;
  onBlur: (event: React.FocusEvent<any>) => void;
}

const DateTimeFieldRenderer = ({
  config,
  field,
  value,
  errorText,
  onChange,
  onBlur,
}: DateTimeFieldRendererProps) => {
  const { isSubmitting } = useFormContext();
  const isDisabled = field.props?.disabled || isSubmitting;
  const Picker = field.type === 'date' ? DatePicker : TimePicker;

  const handleChange = (date: Moment | null) => {
    onChange(date ? date.toDate() : null);
  };

  return (
    <Box>
      <Picker
        disabled={isDisabled}
        label={field.label}
        value={value ? moment(value) : null}
        onChange={handleChange}
        slots={{ textField: MuiTextField }}
        slotProps={{
          textField: {
            spellCheck: false,
            fullWidth: true,
            size: config?.size || 'small',
            error: Boolean(errorText),
            helperText: errorText,
            onBlur,
          },
        }}
      />
    </Box>
  );
};

export default DateTimeFieldRenderer;
