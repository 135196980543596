import React from 'react';
import { Box } from '@mui/material';
import { useFormContext } from '../context';
import type { FormFieldCustom } from '../types/fields.types';
import type { FormConfig } from '../types/form.types';

interface Props {
  config?: FormConfig;
  field: FormFieldCustom;
  value: any;
  errorText?: string;
  onChange: (value: any) => void;
  onBlur: (event: React.FocusEvent<any>) => void;
}

const CustomComponentRenderer = ({
  config,
  field,
  value,
  errorText,
  onChange,
  onBlur,
}: Props) => {
  const { isSubmitting } = useFormContext();
  const CustomComponent = field.component;

  return (
    <Box
      sx={{
        transition: 'opacity 0.2s',
        ...(isSubmitting && {
          pointerEvents: 'none',
          opacity: 0.5,
        }),
      }}
    >
      <CustomComponent
        {...field}
        value={value}
        onChange={(val: any) => onChange(val)}
        onBlur={onBlur}
      />
      {errorText && (
        <Box sx={{ color: 'error.main', fontSize: '0.8rem', mt: 0.5 }}>
          {errorText}
        </Box>
      )}
    </Box>
  );
};

export default CustomComponentRenderer;
