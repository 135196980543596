
import api from '../base';

import type { Paths, Response, Schemas } from '../types';

export interface UserProfile {
    is_admin_role: boolean;
    two_factor_enabled: boolean;
}

export interface UserSettings {
    about: string;
    birthday: string | null;
    created_at: string;
    updated_at: string;
}

export interface UserData {
    id: number;
    uuid: string;
    username: string;
    email: string;
    first_name: string;
    profile: UserProfile;
    settings: UserSettings;
}

export type TokenResponse = Schemas['TokenRefresh']
export type TokenObtainRequest = Schemas['CustomTokenObtainPairRequest'] & {
    two_factor_token?: string;
};
export type RegisterRequest = Schemas['UserRegistrationRequest'];
export type UserVerificationRequest = Schemas['UserVerificationRequest'];
export type UserVerificationResponse = Schemas['UserVerification'] & {
    success: boolean;
};

export type TokenRefreshRequest = Schemas['TokenRefreshRequest'];
export type TokenRefreshResponse = Schemas['TokenRefresh'];

class AuthApi {

    static tokenObtain(data: TokenObtainRequest): Response<TokenResponse> {
        const url: keyof Paths = '/dj/accounts/token/';
        return api.post({ url, data });
    }

    static checkToken(): Response<UserData> {
        const url: keyof Paths = '/dj/accounts/token/check/';
        return api.get({ url });
    }

    static register(data: RegisterRequest): Response<TokenResponse> {
        const url: keyof Paths = '/dj/accounts/register/';
        return api.post({ url, data });
    }

    static tokenRefresh(data: TokenRefreshRequest): Response<TokenRefreshResponse> {
        const url: keyof Paths = '/dj/accounts/token/refresh/';
        return api.post({ url, data });
    }

    static passwordResetRequest(data: Schemas['PasswordResetRequest']): Response<TokenResponse> {
        const url: keyof Paths = '/dj/accounts/password/reset/';
        return api.post({ url, data });
    }

    static passwordResetConfirm(data: Schemas['SetNewPasswordRequest']): Response<TokenResponse> {
        const url: keyof Paths = '/dj/accounts/password/reset/confirm/';
        return api.post({ url, data });
    }

    static verifyEmail(data: UserVerificationRequest): Response<UserVerificationResponse> {
        const url: keyof Paths = '/dj/accounts/verify-email/';
        return api.post({ url, data });
    }

}

export default AuthApi;
